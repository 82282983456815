import { luckyRolls } from '@/services/resources'

export const path = {
  luckyRolls: {
    path: '/lucky-rolls',
    name: 'lucky-rolls',
    meta: {
      pageTitle: 'Lucky rolls',
      resources: [luckyRolls.getAll]
    }
  },
  luckyRollsWinners: {
    path: '/lucky-rolls/winners',
    name: 'lucky-rolls-winners',
    meta: {
      pageTitle: 'Winners',
      resources: [luckyRolls.winners]
    }
  },
  luckyRollCreate: {
    path: '/lucky-rolls/new',
    name: 'lucky-roll-create',
    meta: {
      pageTitle: 'Create Lucky Roll',
      resources: [luckyRolls.create]
    }
  },
  luckyRollEdit: {
    path: '/lucky-rolls/:id',
    name: 'lucky-roll-edit',
    meta: {
      pageTitle: 'Update Lucky Roll',
      resources: [
        luckyRolls.update,
        luckyRolls.getOne,
        luckyRolls.prizesUpdate,
        luckyRolls.prizeToggle
      ]
    }
  }
}

export const luckyRollsRoutes = [
  {
    ...path.luckyRolls,
    component: () => import('@/views/lucky-rolls/LuckyRolls')
  },
  {
    ...path.luckyRollsWinners,
    component: () => import('@/views/lucky-rolls/LuckyRollsWinners')
  },
  {
    ...path.luckyRollCreate,
    component: () => import('@/views/lucky-rolls/LuckyRollWithPrizeEditor')
  },
  {
    ...path.luckyRollEdit,
    component: () => import('@/views/lucky-rolls/LuckyRollWithPrizeEditor')
  }
]
