import { permissions } from '@/plugins/acl'
import api from '@/services/api'

const recordsPath = '/antifraud-records'
const activationsPath = '/antifraud-activations'
const credentialsPath = '/antifraud-credentials'

const activationEntityName = 'Activation'
const credentialEntityName = 'Credential'

export default {
  getAllRecords: {
    permission: permissions.antifraudRecordView,
    request: ({ params }) => api.get(recordsPath, params)
  },
  getAllActivations: {
    permission: permissions.antifraudActivationView,
    request: ({ params }) => api.get(activationsPath, params)
  },
  getOneActivation: {
    permission: permissions.antifraudActivationView,
    request: ({ urlParams: { id }, params }) => api.get(`${activationsPath}/${id}`, params)
  },
  createActivation: {
    permission: permissions.antifraudActivationCreate,
    entity: activationEntityName,
    request: ({ data }) => api.post(activationsPath, data)
  },
  appealActivation: {
    permission: permissions.antifraudActivationUpdate,
    entity: activationEntityName,
    request: ({ urlParams: { id } }) => api.put(`${activationsPath}/${id}/appeal`)
  },
  inspectActivation: {
    permission: permissions.antifraudActivationUpdate,
    entity: activationEntityName,
    request: ({ urlParams: { id } }) => api.put(`${activationsPath}/${id}/inspect`)
  },
  undoInspectActivation: {
    permission: permissions.antifraudActivationUpdate,
    entity: activationEntityName,
    request: ({ urlParams: { id } }) => api.put(`${activationsPath}/${id}/undo-inspection`)
  },
  getAllCredentials: {
    permission: permissions.antifraudCredentialView,
    request: ({ params }) => api.get(credentialsPath, params)
  },
  createCredential: {
    permission: permissions.antifraudCredentialCreate,
    entity: credentialEntityName,
    request: ({ urlParams: { type }, data }) => api.post(`${credentialsPath}/${type}`, data)
  },
  updatedCredential: {
    permission: permissions.antifraudCredentialUpdate,
    entity: credentialEntityName,
    request: ({ urlParams: { id }, data }) => api.put(`${credentialsPath}/${id}`, data)
  }
}
