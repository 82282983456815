// TODO: use cookies instead of localstorage

class TokenService {
  get token () {
    return localStorage.getItem(`access_token`)
  }

  set token (token) {
    token
      ? localStorage.setItem(`access_token`, token)
      : localStorage.removeItem(`access_token`)
  }

  get refreshToken () {
    return localStorage.getItem(`refresh_token`)
  }

  set refreshToken (token) {
    token
      ? localStorage.setItem(`refresh_token`, token)
      : localStorage.removeItem(`refresh_token`)
  }

  resetTokens () {
    this.token = ''
    this.refreshToken = ''
  }

  setTokens ({ access_token, refresh_token }) {
    tokenService.token = access_token
    tokenService.refreshToken = refresh_token
  }

  hasTokens () {
    return Boolean(this.token && this.refreshToken)
  }
}

const tokenService = new TokenService()

export default tokenService
