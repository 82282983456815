import { welcomeOffers } from '@/services/resources'

export const path = {
  welcomeOffers: {
    path: '/welcome-offers',
    name: 'welcome-offers',
    meta: {
      pageTitle: 'Welcome Offers',
      resources: [welcomeOffers.getAll]
    }
  },
  welcomeOffersUsed: {
    path: '/welcome-offers-used',
    name: 'welcome-offers-used',
    meta: {
      pageTitle: 'Used Welcome Offers',
      resources: [welcomeOffers.getAllUsed]
    }
  }
}

export const welcomeOffersRoutes = [
  {
    ...path.welcomeOffers,
    component: () => import('@/views/welcome-offers/WelcomeOffers')
  },
  {
    ...path.welcomeOffersUsed,
    component: () => import('@/views/welcome-offers/WelcomeOffersUsed')
  }
]
