// APP CONFIG
export const $themeConfig = {
  app: {
    appName: 'Admin', // Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require
    appLogoImage: require('@/assets/images/logo/logo.svg') // Will update logo in navigation menu (Branding)
  },
  layout: {
    skin: 'bordered', // light, dark, bordered, semi-dark
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    contentWidth: 'boxed', // full, boxed
    menu: {
      hidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'static' // static , sticky , floating, hidden
    }
  }
}
