import api from '@/services/api'
import { permissions } from '@/plugins/acl'

const botsPath = '/bots'
const botEntityName = 'Bot'

export default {
  getAll: {
    permission: permissions.botView,
    request: ({ params }) => api.get(botsPath, params)
  },
  getOne: {
    permission: permissions.botView,
    request: ({ urlParams: { id } }) => api.get(`${botsPath}/${id}`)
  },
  create: {
    permission: permissions.botCreate,
    entity: botEntityName,
    request: ({ data }) => api.post(botsPath, data)
  },
  update: {
    permission: permissions.botUpdate,
    entity: botEntityName,
    request: ({ urlParams: { id }, data }) => api.put(`${botsPath}/${id}`, data)
  },
  updateStatus: {
    permission: permissions.botStatusUpdate,
    entity: 'Bot status',
    request: ({ urlParams: { id }, data }) => api.put(`${botsPath}/${id}/status`, data)
  },
  delete: {
    permission: permissions.botUpdate,
    entity: botEntityName,
    confirm: true,
    request: ({ urlParams: { id } }) => api.delete(`${botsPath}/${id}`)
  },
  getMarkets: {
    permission: permissions.botMarketView,
    request: ({ urlParams: { id } }) => api.get(`${botsPath}/${id}/bot-markets`)
  },
  createMarket: {
    permission: permissions.botMarketCreate,
    entity: 'Bot market',
    request: ({ urlParams: { id }, data }) => api.post(`${botsPath}/${id}/bot-markets`, data)
  },
  updateMarket: {
    permission: permissions.botMarketUpdate,
    entity: 'Bot market',
    request: ({ urlParams: { botId, marketId }, data }) => {
      return api.put(`${botsPath}/${botId}/bot-markets/${marketId}`, data)
    }
  },
  deleteMarket: {
    permission: permissions.botUpdate,
    entity: 'Bot market',
    confirm: true,
    request: ({ urlParams: { botId, marketId } }) => {
      return api.delete(`${botsPath}/${botId}/bot-markets/${marketId}`)
    }
  },
  getSteamData: {
    permission: permissions.botSteamdataView,
    request: ({ urlParams: { id } }) => api.get(`${botsPath}/${id}/steamdata`)
  },
  createSteamData: {
    permission: permissions.botSteamdataCreate,
    entity: 'Steam data',
    request: ({ urlParams: { id }, data }) => api.post(`${botsPath}/${id}/steamdata`, data)
  },
  updateSteamData: {
    permission: permissions.botSteamdataUpdate,
    entity: 'Steam data',
    request: ({ urlParams: { botId, steamDataId }, data }) => {
      return api.put(`${botsPath}/${botId}/steamdata/${steamDataId}`, data)
    }
  }
}
