import { drops } from '@/services/resources'

export const path = {
  drops: {
    path: '/drops',
    name: 'drops',
    meta: {
      resources: [drops.getAll],
      pageTitle: 'Drops'
    }
  }
}

export const dropsRoutes = [
  {
    ...path.drops,
    component: () => import('@/views/drops/Drops')
  }
]
