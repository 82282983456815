import { tags } from '@/services/resources'

export const path = {
  tagsTable: {
    path: '/tags',
    name: 'tags',
    meta: {
      pageTitle: 'Tags',
      resources: [
        tags.getAll,
        tags.update,
        tags.create,
        tags.delete
      ]
    }
  }
}

export const tagsRoutes = [
  {
    ...path.tagsTable,
    component: () => import('@/views/tags/Tags.vue')
  }
]
