import Vue from 'vue'
import Vuex from 'vuex'

// Core modules, don't touch
import app from '@/store/app'
import appConfig from '@/store/template/app-config'
import verticalMenu from '@/store/template/vertical-menu'
import user from '@/store/user'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.DEV,
  modules: {
    app,
    appConfig,
    verticalMenu,
    user
  }
})
