export const steamAvatarSize = {
  medium: '_medium',
  full: '_full'
}

export const steamAvatarExtension = {
  jpg: '.jpg'
}

export const tagsVisibility = {
  private: 'private',
  public: 'public',
  hidden: 'hidden'
}

export const welcomeOfferUsedStatuses = {
  accepted: 'accepted',
  'in-progress': 'in-progress',
  completed: 'completed',
  canceled: 'canceled',
  failed: 'failed',
  closed_after_fail: 'closed_after_fail',
  declined: 'declined'
}

export const dropReasonTypes = {
  'chest-opening': 'chest-opening',
  upgrade: 'upgrade',
  exchange: 'exchange',
  'lucky-roll-result': 'lucky-roll-result'
}

export const dropStatuses = {
  pending: 'pending',
  sold: 'sold',
  buying: 'buying',
  sending: 'sending',
  sent: 'sent',
  delivered: 'delivered',
  upgraded: 'upgraded',
  exchanged: 'exchanged',
  suspended: 'suspended',
  waiting: 'waiting',
  error: 'error',
  canceled: 'canceled'
}

export const balanceChangeReasonTypes = {
  exchange: 'exchange',
  chestOpening: 'chest-opening',
  user: 'user',
  balanceChangeRequest: 'balance-change-request',
  drop: 'drop',
  payment: 'payment',
  bonusPlayer: 'bonus-player'
}

export const upgradeWinMap = {
  0: 'Lost',
  1: 'Win'
}

export const paymentSystemsMap = {
  zen: 'zen',
  ecommpay: 'ecommpay',
  skinsback: 'skinsback',
  accey: 'accey',
  giftCard: 'gift-card',
  minegate: 'minegate',
  g2apay: 'g2apay'
}

export const paymentStatuses = {
  pending: 'pending',
  checked: 'checked',
  success: 'success',
  error: 'error',
  canceled: 'canceled'
}

export const paymentStatusColors = {
  [paymentStatuses.canceled]: 'warning',
  [paymentStatuses.checked]: 'primary',
  [paymentStatuses.error]: 'danger',
  [paymentStatuses.pending]: 'primary',
  [paymentStatuses.success]: 'success'
}

export const bonusTypes = {
  money: 'money-bonus',
  refill: 'refill-bonus'
}

export const bonusTypesOptions = [
  {
    label: 'Refill',
    value: bonusTypes.refill
  },
  {
    label: 'Money',
    value: bonusTypes.money
  }
]

export const bonusStatuses = {
  desired: 'desired',
  applied: 'applied',
  ignored: 'ignored'
}

export const boolStatuses = {
  0: 'No',
  1: 'Yes'
}

export const playerStatuses = {
  active: 'active',
  blocked: 'blocked',
  frozen: 'frozen'
}

export const credentialTypes = {
  card: 'card',
  bin: 'bin'
}

export const credentialStatuses = {
  unsafe: 'unsafe',
  whitelisted: 'whitelisted',
  blacklisted: 'blacklisted'
}

export const bonusUsageStatuses = {
  active: 'active',
  expired: 'expired',
  used: 'used',
  disabled: 'disabled'
}

export const bonusUsageReasonTypes = {
  promocode: 'promocode',
  welcomeOffer: 'welcome-offer'
}

export const bonusesTypes = { fixed: 'fixed', percentage: 'percentage' }

export const playerBonusType = { money: 'money-bonus', refill: 'refill-bonus' }

export const balanceChangeRequestStatuses = {
  pending: 'pending',
  approved: 'approved',
  rejected: 'rejected'
}

export const activityTypesEnum = {
  all: 'all',
  chestOpening: 'chest-opening',
  upgrade: 'upgrade'
}

export const caseTypes = {
  free: 'free',
  usualCase: 'usual-case',
  brcPriced: 'brc-priced',
  quantityLimited: 'quantity-limited',
  timeLimited: 'time-limited'
}

export const rarities = {
  consumer: 'consumer',
  industrial: 'industrial',
  restricted: 'restricted',
  covert: 'covert',
  milspec: 'milspec',
  classified: 'classified',
  contraband: 'contraband',
  exceedingly: 'exceedingly'
}

export const botEnvs = {
  prod: 'prod',
  stage: 'stage',
  dev: 'dev'
}

export const botStatuses = {
  active: 'active',
  inactive: 'inactive'
}

export const botPurposes = {
  stats: 'stats',
  buysender: 'buysender'
}

export const caseSectionLocations = [
  { value: 'home', label: 'Home' },
  { value: 'brc', label: 'BRC' },
  { value: 'all', label: 'All' }
]

export const promocodeLink = (code) => {
  return `${process.env.VUE_APP_CSBRO}?promo=${code}`
}

export const digitalOceanPath = (path) => {
  return `${process.env.VUE_APP_DO_ENDPOINT}/${path}`
}

export const missionTypes = {
  starter: 'starter',
  regular: 'regular'
}

export const taskTypes = {
  case_opened: 'case_opened',
  visit_link: 'visit_link',
  drops_sum: 'drops_sum',
  drops_quantity: 'drops_quantity',
  upgrade: 'upgrade',
  skin_withdrawal: 'skin_withdrawal'
}

export const taskUpgradeIsWinTypes = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
  { value: null, label: 'Any' }
]

export const taskReasons = {
  any: 'any',
  upgrade: 'upgrade',
  caseOpening: 'case-opening'
}

/* TODO: looks like a deprecated. Probably remove */
export const caseIconTypes = [
  {
    title: 'General',
    value: 'general'
  },
  {
    title: 'By rarity',
    value: 'by_rarity'
  },
  {
    title: 'Steam collection',
    value: 'steam_collection'
  },
  {
    title: 'Team case',
    value: 'team_case'
  },
  {
    title: 'Youtube',
    value: 'youtube'
  }
]

export const caseLabelDefault = 'none'

export const editorTypes = {
  item: 'item',
  prize: 'prize'
}

export const defaultPrizeValues = {
  description: '',
  is_active: true,
  entity_id: null,
  entity_type: 'item'
}
