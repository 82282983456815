import { addSeconds, format, isBefore, isSameDay, isValid, parseISO } from 'date-fns'

const datePickerFormats = {
  date: 'D MMM YYYY',
  datetime: 'YYYY-MM-DD H:mm:ss',
  time: 'HH:mm:ss'
}

const dateFormats = {
  date: 'd MMM yyyy',
  datetime: 'yyyy-MM-dd HH:mm:ss',
  time: 'HH:mm:ss'
}

const datePickerTypes = {
  date: 'date',
  datetime: 'datetime',
  time: 'time'
}

const datePickerLang = {
  formatLocale: {
    firstDayOfWeek: 1
  }
}

const datePickerValueTypes = {
  [datePickerTypes.date]: 'date',
  [datePickerTypes.datetime]: 'date',
  [datePickerTypes.time]: datePickerFormats.time
}

const isDateRange = (range) => {
  return Array.isArray(range) && range.length === 2
}

const isRangeValid = (range = []) => {
  if (!isDateRange(range)) return false

  return range.reduce((res, value) => {
    if (!res) return res
    return isValid(value)
  }, true)
}

const parseISODate = (value) => {
  const parsedValue = parseISO(value)
  return isValid(parsedValue) ? parsedValue : value
}

const dateFormat = (value, dateFormat = 'yyyy-MM-dd HH:mm:ss') => {
  return value && format(parseISODate(value), dateFormat)
}

const formatToISODate = (value) => {
  return new Date(value).toISOString()
}

const daysToSeconds = (value) => {
  return value * 24 * 60 * 60
}

const secondsToDays = (value) => {
  return Math.round(value / 86400)
}

const formatFromSecondsToHours = value => {
  return value ? value / 3600 : value
}

const formatFromHoursToSeconds = value => {
  return value ? value * 3600 : value
}

const hoursToDays = (value) => {
  return Math.round(value / 24)
}

const secondsToTime = (value) => {
  return new Date(value * 1000).toISOString().substr(11, 8)
}

const timeToSeconds = (value) => {
  return value.split(':').reduce((acc, time) => (60 * acc) + +time)
}

const isDayBefore = (date) => {
  return isSameDay(date, new Date()) ? false : isBefore(date, new Date())
}

const addSecondsToISODate = (date, seconds) => {
  const formattedDate = parseISODate(date)
  const dateWithSeconds = addSeconds(formattedDate, seconds)
  return formatToISODate(dateWithSeconds)
}

export {
  formatToISODate,
  parseISODate,
  dateFormats,
  datePickerFormats,
  datePickerValueTypes,
  datePickerTypes,
  datePickerLang,
  dateFormat,
  daysToSeconds,
  formatFromSecondsToHours,
  formatFromHoursToSeconds,
  hoursToDays,
  secondsToDays,
  secondsToTime,
  timeToSeconds,
  isDayBefore,
  isRangeValid,
  addSecondsToISODate
}
