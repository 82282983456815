import { permissions } from '@/plugins/acl'
import api from '@/services/api'

const skinsPath = '/skins'

export default {
  getAll: {
    permission: permissions.skinView,
    request: ({ params }) => api.get(skinsPath, params)
  },
  checkNew: {
    permission: permissions.skinView,
    request: () => api.get(`${skinsPath}/check/new`)
  },
  create: {
    permission: permissions.skinCreate,
    suppressNotification: true,
    request: ({ data }) => api.post(`${skinsPath}/create`, data)
  },
  update: {
    permission: permissions.skinUpdate,
    suppressNotification: true,
    request: ({ data }) => api.put(`${skinsPath}/update`, data)
  }
}
