<template>
  <validation-provider
    v-slot="{ errors, valid }"
    :rules="rules"
    :name="name"
    :vid="name"
    slim
  >
    <b-form-group class="wrapper">
      <label v-if="label" :for="name">
        {{ label }}
      </label>
      <b-input-group>
        <date-picker
          v-bind="$attrs"
          :id="name"
          v-model="innerRange"
          :type="type"
          :format="format"
          :editable="false"
          :lang="datePickerLang"
          class="w-100"
          range-separator=" - "
          range
          @input="emitValue"
        >
          <template v-if="sidebarShortcuts" #sidebar="{ emit, value }">
            <button
              v-for="({ text, range }, key) in sidebarShortcuts"
              :key="key"
              :class="shortcutClasses(range, value)"
              @click="emit(range)"
            >
              {{ text }}
            </button>
          </template>
        </date-picker>
        <small v-if="rangeDuration">
          {{ rangeDuration }}
        </small>
      </b-input-group>
      <small v-if="!valid" class="text-danger">
        {{ errors && errors[0] }}
      </small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { BFormGroup, BInputGroup } from 'bootstrap-vue'
import { computed, unref } from '@vue/composition-api'
import datePicker from 'vue2-datepicker'
import { datePickerFormats, datePickerLang, formatToISODate, isRangeValid, parseISODate } from '@/services/utils'
import {
  formatDistanceStrict,
  isSameDay,
  isSameHour,
  isValid,
  parseISO
} from 'date-fns'
import { map } from 'lodash'

export default {
  name: 'VxDateRangePicker',
  components: { BFormGroup, BInputGroup, ValidationProvider, datePicker },
  props: {
    value: {
      type: Array,
      default: () => [null, null]
    },
    rules: {
      type: [Object, String],
      default: () => ({})
    },
    label: {
      type: String,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'date'
    },
    format: {
      type: String,
      default () {
        return datePickerFormats[this.type]
      }
    },
    sidebarShortcuts: {
      type: Array,
      default: null
    },
    showRangeDuration: Boolean
  },
  emits: ['input'],
  setup (props, { emit }) {
    const formatInputValues = (range) => {
      return range.map((value) => value ? parseISO(value) : value)
    }

    const formatOutputValues = (range) => {
      return range.map((value) => isValid(value) ? formatToISODate(value) : value)
    }

    const emitValue = (value) => {
      emit('input', formatOutputValues(value))
    }

    const innerRange = computed({
      get: () => formatInputValues(props.value),
      // hack for vee-validate, provider doesn't work without v-model
      set: (newValue) => newValue
    })

    const shortcutClasses = (shortcutRange, selectedRange) => {
      return {
        'mx-btn': true,
        'mx-btn-text': true,
        'mx-btn-active': isShortcutRangeSelected(shortcutRange, selectedRange)
      }
    }

    const isShortcutRangeSelected = (shortcutRange, selectedRange) => {
      return selectedRange.reduce((res, innerRangeEl, index) => {
        if (!res) return false

        switch (props.type) {
          case 'date':
            return isSameDay(innerRangeEl, shortcutRange[index])
          case 'datetime':
            return isSameHour(innerRangeEl, shortcutRange[index])
          default:
            return false
        }
      }, true)
    }

    const rangeDuration = computed(() => {
      if (!props.showRangeDuration) return false

      const parsedRange = map(unref(innerRange), parseISODate)

      if (!isRangeValid(parsedRange)) return false

      const distance = formatDistanceStrict(parsedRange[1], parsedRange[0], { unit: 'hour' })
      return `~ ${distance}`
    })

    return {
      innerRange,
      emitValue,
      shortcutClasses,
      isShortcutRangeSelected,
      datePickerLang,
      rangeDuration
    }
  }
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/date-picker.scss';
</style>
