import Vue from 'vue'
import VueRouter from 'vue-router'
import path from '@/router/path'
import routes from '@/router/routes'
import { checkAuth, checkPermission } from '@/router/guards'
import {
  getRedirectQuery,
  buildRoute,
  routerQueryIsEqualWith,
  useRouter,
  isRouteActive,
  getRouteIdParam
} from '@/router/utils'
import { routerDataResolver } from '@/router/routerDataResolver'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(checkAuth)
router.beforeEach(checkPermission)

export default router

export {
  path,
  useRouter,
  isRouteActive,
  getRouteIdParam,
  getRedirectQuery,
  routerQueryIsEqualWith,
  buildRoute,
  routerDataResolver
}
