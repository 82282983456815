import api from '@/services/api'
import { permissions } from '@/plugins/acl'

const paymentSystemsUrl = '/payment-systems'

export default {
  getAll: {
    permission: permissions.paymentSystemsView,
    request: ({ params }) => api.get(paymentSystemsUrl, params)
  },
  getOne: {
    permission: permissions.paymentSystemsView,
    request: ({ urlParams: { id }, params }) => {
      return api.get(`${paymentSystemsUrl}/${id}`, params)
    }
  },
  updateGateway: {
    permission: permissions.paymentSystemsUpdate,
    entity: 'Payment gateway',
    request: ({ urlParams: { id }, data }) => {
      return api.put(`${paymentSystemsUrl}/${id}`, data)
    }
  },
  updatePositions: {
    permission: permissions.paymentSystemsUpdate,
    entity: 'Payment systems',
    request: ({ data }) => api.put(`${paymentSystemsUrl}/order`, data)
  },
  getCountries: {
    permission: permissions.paymentSystemsView,
    request: () => api.get(`${paymentSystemsUrl}/countries`)
  },
  updateCountries: {
    permission: permissions.paymentSystemsUpdate,
    entity: 'Payment countries',
    request: ({ data }) => api.put(`${paymentSystemsUrl}/countries`, data)
  }
}
