import { passDataToResource, withdrawals } from '@/services/resources'
import { routerDataResolver } from '@/router'

export const path = {
  withdrawals: {
    path: '/withdrawals',
    name: 'withdrawals',
    meta: {
      pageTitle: 'Withdrawals',
      resources: [withdrawals.getAll]
    }
  },
  withdrawal: {
    path: '/withdrawals/:id',
    name: 'withdrawal',
    meta: {
      pageTitle: 'Withdrawal',
      resources: [withdrawals.getOne]
    }
  }
}

export const withdrawalsRoutes = [
  {
    ...path.withdrawals,
    component: () => import('@/views/withdrawals/Withdrawals')
  },
  {
    ...path.withdrawal,
    component: () => import('@/views/withdrawals/Withdrawal'),
    props: true,
    beforeEnter: (to, from, next) => {
      return routerDataResolver(
        to,
        next,
        passDataToResource(withdrawals.getOne, {
          requestParams: {
            id: to.params.id,
            params: {}
          }
        })
      )
    }
  }
]
