import { permissions } from '@/plugins/acl'
import api from '@/services/api'

const rolesPath = '/roles'
const roleEntity = 'Role'

export default {
  getAll: {
    permission: permissions.roleView,
    request: ({ params }) => api.get(rolesPath, params)
  },
  getOne: {
    permission: permissions.roleView,
    request: ({ urlParams: { id }, params }) => api.get(`${rolesPath}/${id}`, params)
  },
  create: {
    permission: permissions.roleCreate,
    entity: roleEntity,
    request: ({ data }) => api.post(rolesPath, data)
  },
  update: {
    permission: permissions.roleUpdate,
    entity: roleEntity,
    request: ({ urlParams: { id }, data }) => api.put(`${rolesPath}/${id}`, data)
  },
  updatePermissions: {
    permission: permissions.roleUpdate,
    entity: 'Role permissions',
    request: ({ urlParams: { id }, data }) => api.put(`${rolesPath}/${id}/permissions`, data)
  },
  delete: {
    permission: permissions.roleDelete,
    entity: roleEntity,
    confirm: true,
    request: ({ urlParams: { id } }) => api.delete(`${rolesPath}/${id}`)
  }
}
