import { giftCards } from '@/services/resources'

export const path = {
  giftCards: {
    path: '/gift-cards',
    name: 'gift-cards',
    meta: {
      pageTitle: 'Gift Cards',
      resources: [giftCards.getAll]
    }
  },
  giftCardsKeysDetails: {
    path: '/gift-cards/details',
    name: 'gift-cards-details',
    meta: {
      pageTitle: 'Gift cards details',
      resources: [giftCards.details]
    }
  },
  giftCardKeys: {
    path: '/gift-cards/:id',
    name: 'gift-card-keys',
    meta: {
      pageTitle: 'Gift Card Keys',
      resources: [giftCards.getKeys]
    }
  }
}

export const giftCardsRoutes = [
  {
    ...path.giftCards,
    component: () => import('@/views/gift-cards/GiftCards')
  },
  {
    ...path.giftCardsKeysDetails,
    component: () => import('@/views/gift-cards/GiftCardsDetails')
  },
  {
    ...path.giftCardKeys,
    component: () => import('@/views/gift-cards/GiftCardKeys')
  }
]
