import api from '@/services/api'
import { permissions } from '@/plugins/acl'

export const playerGetOneIncludes = [
  'bestDrop', 'brcAccount', 'paymentAccount', 'paymentAccount.lastSuccessfulPayment',
  'playerTotals', 'availablePivotRefillBonuses.bonus', 'upgradeStats', 'promocodeStats',
  'bonusStats', 'moneyStats', 'exchangeStats', 'dropStats', 'dropSums', 'chestStats',
  'dropSums', 'withdrawDropLimit', 'profile', 'profile.latestStatusChange',
  'profile.statusChanges', 'latestClientSeed', 'latestServerSeed', 'steamProfile',
  'tracking', 'activeAntifraudActivation', 'tags', 'playerWelcomeOffer'
].join(',')

const playersUrl = '/players'
const entityName = 'Player'
const entityWithdrawalLimit = 'Withdrawal limit'

export default {
  getAll: {
    permission: permissions.playerView,
    request: ({ params }) => api.get(playersUrl, params)
  },
  getOne: {
    permission: permissions.playerView,
    request: ({ urlParams: { id }, params }) => api.get(`${playersUrl}/${id}`, params)
  },
  changeStatus: {
    permission: permissions.playerUpdate,
    entity: entityName,
    request: ({ urlParams: { id }, data }) => api.put(`${playersUrl}/${id}/change-profile-status`, data)
  },
  refreshSteamData: {
    permission: permissions.playerUpdate,
    request: ({ urlParams: { id } }) => api.put(`${playersUrl}/${id}/refresh-steam-data`)
  },
  withdrawalLimit: {
    permission: permissions.playerWithdrawDropLimitView,
    request: ({ urlParams: { id } }) => api.get(`${playersUrl}/${id}/withdraw-drop-limit`)
  },
  updateWithdrawalLimit: {
    permission: permissions.playerWithdrawDropLimitUpdate,
    entity: entityWithdrawalLimit,
    request: ({ urlParams: { id }, data }) => api.put(`${playersUrl}/${id}/withdraw-drop-limit`, data)
  },
  createWithdrawalLimit: {
    permission: permissions.playerWithdrawDropLimitCreate,
    entity: entityWithdrawalLimit,
    request: ({ urlParams: { id }, data }) => api.post(`${playersUrl}/${id}/withdraw-drop-limit`, data)
  },
  updateTags: {
    permission: permissions.playerTagUpdate,
    entity: entityName,
    request: ({ urlParams: { id }, data }) => api.post(`${playersUrl}/${id}/tags`, data)
  },
  accounts: {
    permission: permissions.accountView,
    request: ({ params }) => api.get('/accounts', params)
  }
}
