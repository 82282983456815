<template>
  <div id="app" class="h-100">
    <header-progress-bar />
    <component :is="layout" v-if="loaded">
      <router-view :key="$route.path" />
    </component>
    <login-modal />
  </div>
</template>

<script>
import { useVuex } from '@vueblocks/vue-use-vuex'
import { provideToast } from '@/plugins/toastification'
import { localStorageWatcher, setAppInstance, useSkin, watchAndStoreWindowWidth, watchLoaded } from '@/services/app'
import LoginModal from '@/views/login/LoginModal'
import HeaderProgressBar from '@/components/layout/HeaderProgressBar'

const LayoutDefault = () => import('@/components/layout/LayoutDefault.vue')
const LayoutFull = () => import('@/components/layout/LayoutFull.vue')

export default {
  components: {
    HeaderProgressBar,
    LayoutDefault,
    LayoutFull,
    LoginModal
  },
  setup () {
    const { initSkin } = useSkin()

    const { useState, useActions } = useVuex('app')
    const { loaded } = useState(['loaded'])
    const { init } = useActions(['init'])

    init()

    watchLoaded().then(() => {
      // Removing splash screen
      const appLoadingElement = document.getElementById('loading-bg')
      if (appLoadingElement) appLoadingElement.style.display = 'none'
    })

    initSkin()

    // Set Window Width in store
    watchAndStoreWindowWidth()

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    provideToast()

    setAppInstance()

    localStorageWatcher()

    return {
      loaded
    }
  },

  computed: {
    layout () {
      const { layout } = this.$route.meta

      switch (true) {
        case layout === 'full':
          return 'layout-full'
        case !layout && this.loaded:
          return 'layout-default'
        default:
          return 'div'
      }
    }
  }
}
</script>
