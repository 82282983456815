import { httpMethods } from '@/services/api/consts'
import { useToast } from '@/plugins/toastification'

const notificationMethods = [
  httpMethods.post,
  httpMethods.put,
  httpMethods.patch,
  httpMethods.delete
]

const notificationMessages = {
  [httpMethods.post]: ({ id, entity }) => `${entity} with id: ${id} has been successfully created.`,
  [httpMethods.put]: ({ id, entity }) => {
    return !id
      ? `${entity} has been successfully updated.`
      : `${entity} with id: ${id} has been successfully updated.`
  },
  [httpMethods.patch]: ({ id, entity }) => {
    return !id
      ? `${entity} with has been successfully updated.`
      : `${entity} with id: ${id} has been successfully updated.`
  },
  [httpMethods.delete]: ({ entity }) => `${entity} has been successfully deleted.`,
  confirmation: ({ entity }) => `Are you sure you want to delete the ${entity}?`
}

const showNotification = ([err, res], entity = null) => {
  const toast = useToast()

  if (err) {
    const errorMessage = err.data.message || err.message || 'An error has occurred. Write to support'

    toast.error(errorMessage)
    return
  }

  const { data, config: { method } } = res

  if (!notificationMethods.includes(method) || !entity) {
    return
  }

  const { data: { id } = {} } = data
  const message = notificationMessages[method]({ id, entity })
  toast.success(message)
}

const formatResponse = ([err, res]) => {
  if (err) return [err, null]

  return [null, res.data]
}

const showConfirmation = (entity) => {
  return confirm(notificationMessages.confirmation({ entity }))
}

export {
  showConfirmation,
  formatResponse,
  showNotification,
  notificationMessages
}
