import { permissions } from '@/plugins/acl'
import api from '@/services/api'

export default {
  getAll: {
    permission: permissions.skinWithdrawalView,
    request: ({ params }) => api.get('/withdrawals', params)
  },
  getOne: {
    permission: permissions.skinWithdrawalView,
    request: ({ id, params }) => api.get(`/withdrawals/${id}`, params)
  },
  statuses: {
    request: () => api.get('/withdrawals/status-list')
  }
}
