import api from '@/services/api'
import { permissions } from '@/plugins/acl'

const faqUrl = '/faq'
const entityName = 'FAQ'

export default {
  getAll: {
    permission: permissions.faqView,
    request: ({ params }) => api.get(faqUrl, params)
  },
  create: {
    permission: permissions.faqCreate,
    entity: entityName,
    request: ({ data }) => api.post(faqUrl, data)
  },
  update: {
    permission: permissions.faqUpdate,
    request: ({ urlParams: { id }, data }) => api.put(`${faqUrl}/${id}`, data)
  },
  delete: {
    permission: permissions.faqDelete,
    entity: entityName,
    confirm: true,
    request: ({ urlParams: { id } }) => api.delete(`${faqUrl}/${id}`)
  },
  updatePositions: {
    permission: permissions.faqUpdate,
    entity: entityName,
    request: ({ data }) => api.put(`${faqUrl}/positions`, data)
  }
}
