import { permissions } from '@/plugins/acl'
import api from '@/services/api'

const systemComponentsUrl = '/system-components'

export default {
  getAll: {
    permission: permissions.systemComponentView,
    request: ({ params }) => api.get(`${systemComponentsUrl}`, params)
  },
  update: {
    permission: permissions.systemComponentUpdate,
    entity: 'Component',
    request: ({ urlParams: { id }, data }) => {
      return api.put(`${systemComponentsUrl}/${id}`, data)
    }
  }
}
