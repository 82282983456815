<template>
  <validation-provider
    v-slot="{ errors, valid }"
    :rules="rules"
    :name="name"
    :vid="name"
    slim
  >
    <b-form-group>
      <label v-if="label" :for="name">
        {{ label }}
      </label>
      <b-input-group>
        <date-picker
          v-bind="$attrs"
          :id="name"
          v-model="innerRange"
          :type="type"
          :value-type="valueType"
          :format="format"
          :editable="false"
          :lang="datePickerLang"
          class="w-100"
        />
      </b-input-group>
      <small v-if="!valid" class="text-danger">
        {{ errors && errors[0] }}
      </small>
    </b-form-group>
  </validation-provider>
</template>
<script>
import { ValidationProvider } from 'vee-validate'
import { BFormGroup, BInputGroup } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'
import datePicker from 'vue2-datepicker'
import {
  datePickerFormats,
  datePickerLang,
  datePickerTypes,
  datePickerValueTypes,
  formatToISODate,
  parseISODate
} from '@/services/utils/date'

export default {
  name: 'VxDatePicker',
  components: {
    ValidationProvider,
    datePicker,
    BFormGroup,
    BInputGroup
  },
  props: {
    value: {
      type: [Date, String],
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'date'
    },
    format: {
      type: String,
      default () {
        return datePickerFormats[this.type] || datePickerFormats.datetime
      }
    },
    valueType: {
      type: String,
      default () {
        return datePickerValueTypes[this.type] || datePickerValueTypes.date
      }
    }
  },
  emits: ['input'],
  setup (props, { emit }) {
    const innerRange = computed({
      get () {
        if ([datePickerTypes.date, datePickerTypes.datetime].includes(props.type)) {
          return parseISODate(props.value)
        }
        return props.value
      },
      set (value) {
        if ([datePickerTypes.date, datePickerTypes.datetime].includes(props.type)) {
          emit('input', formatToISODate(value))
        } else {
          emit('input', value)
        }
      }
    })

    return {
      innerRange,
      datePickerLang
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/date-picker.scss';
</style>
