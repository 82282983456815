import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: () => ({
    layout: {
      skin: $themeConfig.layout.skin,
      routerTransition: $themeConfig.layout.routerTransition,
      contentWidth: $themeConfig.layout.contentWidth,
      menu: {
        hidden: $themeConfig.layout.menu.hidden
      },
      navbar: {
        type: $themeConfig.layout.navbar.type
      }
    }
  }),
  getters: {},
  mutations: {
    UPDATE_SKIN (state, skin) {
      state.layout.skin = skin
    },
    UPDATE_ROUTER_TRANSITION (state, val) {
      state.layout.routerTransition = val
    },
    UPDATE_CONTENT_WIDTH (state, val) {
      state.layout.contentWidth = val
    },
    UPDATE_NAV_MENU_HIDDEN (state, val) {
      state.layout.menu.hidden = val
    },
    UPDATE_NAVBAR_CONFIG (state, obj) {
      Object.assign(state.layout.navbar, obj)
    }
  }
}
