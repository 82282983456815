export const permissions = {
  userCreate: 'user.create',
  userView: 'user.view',
  userUpdate: 'user.update',
  userToggleBan: 'user.toggle-ban',
  userPermissionView: 'user.permission.view',
  userPermissionUpdate: 'user.permission.update',
  userPasswordUpdate: 'user.password.update',
  permissionView: 'permission.view',
  roleCreate: 'role.create',
  roleView: 'role.view',
  roleUpdate: 'role.update',
  roleDelete: 'role.delete',
  chestCreate: 'chest.create',
  chestView: 'chest.view',
  chestUpdate: 'chest.update',
  chestDelete: 'chest.delete',
  chestCategoryView: 'chest.category.view',
  chestCategoryUpdate: 'chest.category.update',
  chestItemView: 'chest.item.view',
  chestItemUpdate: 'chest.item.update',
  chestGenerationView: 'chest.generation.view',
  chestGenerationCreate: 'chest.generation.create',
  chestGenerationUpdate: 'chest.generation.update',
  chestContentView: 'chest.content.view',
  chestContentUpdate: 'chest.content.update',
  chestBorderCreate: 'chest.border.create',
  chestBorderView: 'chest.border.view',
  chestBorderUpdate: 'chest.border.update',
  chestBorderDelete: 'chest.border.delete',
  chestChanceRangeCreate: 'chest.chance-range.create',
  chestChanceRangeView: 'chest.chance-range.view',
  chestChanceRangeUpdate: 'chest.chance-range.update',
  chestChanceRangeDelete: 'chest.chance-range.delete',
  chestStatisticsView: 'chest.statistics.view',
  chestOpeningView: 'chest-opening.view',
  sectionCreate: 'section.create',
  sectionView: 'section.view',
  sectionUpdate: 'section.update',
  sectionDelete: 'section.delete',
  sectionUpdatePositions: 'section.update-positions',
  currencyCreate: 'currency.create',
  currencyView: 'currency.view',
  currencyUpdate: 'currency.update',
  currencyDelete: 'currency.delete',
  categoryCreate: 'category.create',
  categoryView: 'category.view',
  categoryUpdate: 'category.update',
  categoryDelete: 'category.delete',
  skinView: 'skin.view',
  skinCreate: 'skin.create',
  skinUpdate: 'skin.update',
  feastCreate: 'feast.create',
  feastView: 'feast.view',
  feastUpdate: 'feast.update',
  feastDelete: 'feast.delete',
  feastChestView: 'feast.chest.view',
  feastChestUpdate: 'feast.chest.update',
  feastPrizeView: 'feast.prize.view',
  feastPrizeUpdate: 'feast.prize.update',
  feastPlayerView: 'feast.player.view',
  moneyPackCreate: 'money-pack.create',
  moneyPackView: 'money-pack.view',
  moneyPackUpdate: 'money-pack.update',
  moneyPackDelete: 'money-pack.delete',
  playerView: 'player.view',
  playerUpdate: 'player.update',
  playerServerSeedView: 'player.server-seed.view',
  playerClientSeedView: 'player.client-seed.view',
  playerCurrencyUpdate: 'player.currency.update',
  playerRestrictionView: 'player.restriction.view',
  playerRestrictionUpdate: 'player.restriction.update',
  playerStatisticsView: 'player.statistics.view',
  playerWithdrawDropLimitView: 'player.withdraw-drop-limit.view',
  playerWithdrawDropLimitCreate: 'player.withdraw-drop-limit.create',
  playerWithdrawDropLimitUpdate: 'player.withdraw-drop-limit.update',
  playerTagUpdate: 'player.tag.update',
  marketView: 'market.view',
  marketUpdate: 'market.update',
  proxyView: 'proxy.view',
  proxyCreate: 'proxy.create',
  proxyUpdate: 'proxy.update',
  proxyDelete: 'proxy.delete',
  dropView: 'drop.view',
  dropUpdate: 'drop.update',
  dropStatisticsView: 'drop.statistics.view',
  dropStatusUpdate: 'drop.status.update',
  promocodeView: 'promocode.view',
  promocodeUpdate: 'promocode.update',
  promocodeCreate: 'promocode.create',
  promocodeUsageView: 'promocode.usage.view',
  promocodeStatisticsView: 'promocode.statistics.view',
  promocodeTagsUpdate: 'promocode.tag.update',
  settingView: 'setting.view',
  settingUpdate: 'setting.update',
  upgradeView: 'upgrade.view',
  upgradeStatisticsView: 'upgrade.statistics.view',
  exchangeView: 'exchange.view',
  exchangeStatisticsView: 'exchange.statistics.view',
  balanceChangeView: 'balance-change.view',
  bonusUsageView: 'bonus-usage.view',
  botView: 'bot.view',
  botCreate: 'bot.create',
  botUpdate: 'bot.update',
  botDelete: 'bot.delete',
  botSteamdataCreate: 'bot.steamdata.create',
  botSteamdataView: 'bot.steamdata.view',
  botSteamdataUpdate: 'bot.steamdata.update',
  botSteamdataDelete: 'bot.steamdata.delete',
  botMarketCreate: 'bot.market.create',
  botMarketView: 'bot.market.view',
  botMarketUpdate: 'bot.market.update',
  botMarketDelete: 'bot.market.delete',
  botStatusUpdate: 'bot.status.update',
  botMarketSecretView: 'bot.market-secret.view',
  tagView: 'tag.view',
  tagCreate: 'tag.create',
  tagUpdate: 'tag.update',
  tagDelete: 'tag.delete',
  notificationView: 'notification.view',
  notificationCreate: 'notification.create',
  notificationUpdate: 'notification.update',
  notificationDelete: 'notification.delete',
  notificationToggleStatus: 'notification.toggle-status',
  systemComponentView: 'system-component.view',
  systemComponentUpdate: 'system-component.update',
  faqView: 'faq.view',
  faqCreate: 'faq.create',
  faqUpdate: 'faq.update',
  faqDelete: 'faq.delete',
  contentView: 'content.view',
  contentCreate: 'content.create',
  contentUpdate: 'content.update',
  contentDelete: 'content.delete',
  translationView: 'translation.view',
  translationCreate: 'translation.create',
  translationUpdate: 'translation.update',
  translationDelete: 'translation.delete',
  bonusView: 'bonus.view',
  bonusCreate: 'bonus.create',
  bonusUpdate: 'bonus.update',
  playerRollView: 'player-roll.view',
  giveawayView: 'giveaway.view',
  giveawayCreate: 'giveaway.create',
  giveawayUpdate: 'giveaway.update',
  giveawayDelete: 'giveaway.delete',
  antifraudCredentialView: 'antifraud.credential.view',
  antifraudCredentialCreate: 'antifraud.credential.create',
  antifraudCredentialUpdate: 'antifraud.credential.update',
  antifraudActivationView: 'antifraud.activation.view',
  antifraudRecordView: 'antifraud.record.view',
  antifraudActivationUpdate: 'antifraud.activation.update',
  antifraudFindCardholder: 'antifraud.find-cardholder',
  antifraudDeleteCardholder: 'antifraud.delete-cardholder',
  antifraudActivationCreate: 'antifraud.activation.create',
  paymentView: 'payment.view',
  paymentStatusUpdate: 'payment.status.update',
  paymentStatisticsView: 'payment.statistics.view',
  paymentSystemsView: 'payment-systems.view',
  paymentSystemsUpdate: 'payment-systems.update',
  generalMetricView: 'general-metric.view',
  incidentView: 'incident.view',
  incidentUpdate: 'incident.update',
  accountView: 'account.view',
  accountUpdate: 'account.update',
  accountCreate: 'account.create',
  adminLogsView: 'admin-logs.view',
  skinWithdrawalView: 'skin-withdrawal.view',
  skinPurchaseView: 'skin-purchase.view',
  balanceChangeRequestCreate: 'balance-change-request.create',
  balanceChangeRequestReviewOwn: 'balance-change-request.review-own',
  balanceChangeRequestReviewAnother: 'balance-change-request.review-another',
  balanceChangeRequestView: 'balance-change-request.view',
  giftCardCreate: 'gift-card.create',
  giftCardUpdate: 'gift-card.update',
  giftCardView: 'gift-card.view',
  giftCardKeyView: 'gift-card.key.view',
  giftCardKeyCreate: 'gift-card.key.create',
  giftCardKeyUpdate: 'gift-card.key.update',
  giftCardKeyCodeView: 'gift-card.key-code.view',
  itemView: 'item.view',
  luckyRollView: 'lucky-roll.view',
  luckyRollCreate: 'lucky-roll.create',
  luckyRollUpdate: 'lucky-roll.update',
  luckyRollDelete: 'lucky-roll.delete',
  luckyRollPrizeView: 'lucky-roll.prize.view',
  luckyRollPrizeCreate: 'lucky-roll.prize.create',
  luckyRollPrizeUpdate: 'lucky-roll.prize.update',
  luckyRollResultView: 'lucky-roll.result.view',
  welcomeOfferView: 'welcome-offer.view',
  welcomeOfferCreate: 'welcome-offer.create',
  welcomeOfferUpdate: 'welcome-offer.update',
  welcomeOfferPlayerView: 'welcome-offer.player.view',
  missionCreate: 'mission.create',
  missionView: 'mission.view',
  missionUpdate: 'mission.update',
  missionDelete: 'mission.delete',
  missionTaskCreate: 'mission.task.create',
  missionTaskView: 'mission.task.view',
  missionTaskUpdate: 'mission.task.update',
  missionTaskDelete: 'mission.task.delete'
}
