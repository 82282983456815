import { permissions } from '@/plugins/acl'
import api from '@/services/api'

const adminsPath = '/users'
const adminEntity = 'Admin'
const logsPath = '/logs'

export default {
  getAll: {
    permission: permissions.userView,
    request: ({ params }) => api.get(adminsPath, params)
  },
  getOne: {
    permission: permissions.userView,
    request: ({ urlParams: { id }, params }) => api.get(`${adminsPath}/${id}`, params)
  },
  create: {
    permission: permissions.userCreate,
    entity: adminEntity,
    request: ({ data }) => api.post(adminsPath, data)
  },
  update: {
    permission: permissions.userUpdate,
    entity: adminEntity,
    request: ({ urlParams: { id }, data }) => api.put(`${adminsPath}/${id}`, data)
  },
  updatePassword: {
    permission: permissions.userPasswordUpdate,
    entity: 'Admin password',
    request: ({ urlParams: { id }, data }) => api.put(`${adminsPath}/${id}/change-password`, data)
  },
  twoFa: {
    permission: permissions.userView,
    request: ({ urlParams: { id }, params }) => api.get(`${adminsPath}/${id}/2fa-info`, params)
  },
  toggleBan: {
    permission: permissions.userToggleBan,
    entity: adminEntity,
    request: ({ urlParams: { id }, data }) => api.put(`${adminsPath}/${id}/toggle-ban`, data)
  },
  logs: {
    permission: permissions.adminLogsView,
    request: ({ params }) => api.get(logsPath, params)
  },
  logsActionNames: {
    request: () => api.get(`${logsPath}/action-names`)
  }
}
