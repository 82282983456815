import api from '@/services/api'
import { permissions } from '@/plugins/acl'

const marketsPath = '/markets'

export default {
  getAll: {
    permission: permissions.marketView,
    request: ({ params }) => api.get(marketsPath, params)
  },
  getOne: {
    permission: permissions.marketView,
    request: ({ urlParams: { id }, params }) => api.get(`${marketsPath}/${id}`, params)
  },
  update: {
    permission: permissions.marketUpdate,
    entity: 'Market',
    request: ({ urlParams: { id }, data }) => api.put(`${marketsPath}/${id}`, data)
  },
  getAllSkins: {
    permission: permissions.marketView,
    request: ({ urlParams: { id }, params }) => api.get(`${marketsPath}/${id}/skins`, params)
  }
}
