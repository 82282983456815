<template>
  <b-overlay
    class="d-inline-block"
    :show="showOverlay"
    :spinner-small="isSpinnerSmall"
    rounded
  >
    <b-button
      v-b-tooltip.hover
      v-bind="$attrs"
      :title="tooltip"
      :disabled="disabled || !canComputed"
      :class="buttonClasses"
      :size="size"
      class="d-flex align-items-center justify-content-center"
      @click="$emit('click', $event)"
    >
      <feather-icon
        v-if="icon"
        :class="iconClasses"
        :icon="icon"
      />
      <slot />
    </b-button>
  </b-overlay>
</template>

<script>
import { BButton, BOverlay } from 'bootstrap-vue'
import { has, isEmpty } from 'lodash'
import { computed, isRef, ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BOverlay
  },
  props: {
    loading: {
      type: [Boolean, Object],
      default: false
    },
    can: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'md'
    },
    tooltip: {
      type: String,
      default: ''
    }
  },
  setup (props, { slots, attrs }) {
    const { icon, size, can } = props

    const canComputed = can && (has(attrs.to, 'can') ? attrs.to.can : true)
    const iconOnly = !!icon && isEmpty(slots)

    const buttonClasses = { 'btn-icon': iconOnly }
    const iconClasses = { 'mr-25': !iconOnly }

    const isSpinnerSmall = size === 'sm'

    const showOverlay = isRef(props.loading)
      ? ref(props.loading)
      : computed(() => props.loading)

    return {
      buttonClasses,
      iconClasses,
      showOverlay,
      isSpinnerSmall,
      canComputed
    }
  }
}
</script>
