import { permissions } from '@/plugins/acl'
import api from '@/services/api'

const balanceChangesPath = '/balance-changes'
const balanceChangeRequestPath = '/balance-change-request'
const balanceChangeRequestEntityName = 'Balance Change Request'

export default {
  getAll: {
    permission: permissions.balanceChangeView,
    request: ({ params }) => api.get(balanceChangesPath, params)
  },
  reasonTypes: {
    request: () => api.get(`${balanceChangesPath}/reason-types`)
  },
  getAllRequests: {
    permission: permissions.balanceChangeRequestView,
    request: ({ params }) => api.get(balanceChangeRequestPath, params)
  },
  createRequest: {
    permission: permissions.balanceChangeRequestCreate,
    entity: balanceChangeRequestEntityName,
    request: ({ data }) => api.post(`${balanceChangeRequestPath}`, data)
  },
  updateRequest: {
    entity: balanceChangeRequestEntityName,
    request: ({ urlParams: { id }, data }) => api.put(`${balanceChangeRequestPath}/${id}`, data)
  },
  approveRequest: {
    entity: balanceChangeRequestEntityName,
    request: ({ urlParams: { id } }) => api.put(`${balanceChangeRequestPath}/${id}/approve`)
  },
  rejectRequest: {
    entity: balanceChangeRequestEntityName,
    request: ({ urlParams: { id } }) => api.put(`${balanceChangeRequestPath}/${id}/reject`)
  }
}
