<template>
  <validation-provider
    v-slot="{ errors, valid }"
    :rules="rules"
    :name="name"
    :vid="name"
    slim
  >
    <b-form-group>
      <label v-if="label" :for="name">
        {{ label }}
      </label>

      <v-select
        :id="name"
        v-model="innerValue"
        v-bind="$attrs"
        :label="optionsLabel"
        :clearable="clearable"
        :options="innerOptions"
        :deselect-from-dropdown="deselectFromDropdown"
        @close="disconnectObserver"
        @open="addOptionsObservation"
        @search="search"
      >
        <template v-if="loading" #spiner>
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </template>
        <template #list-footer>
          <span ref="optionsListFooter" />
        </template>
      </v-select>

      <small v-if="!valid" class="text-danger">
        {{ errors && errors[0] }}
      </small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import { BFormGroup } from 'bootstrap-vue'
import { computed, nextTick, unref, watch } from '@vue/composition-api'

export default {
  name: 'VxSelect',
  components: {
    vSelect,
    ValidationProvider,
    BFormGroup
  },
  props: {
    name: {
      type: String,
      required: true
    },
    options: {
      type: [Array, Object],
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [Number, String, Boolean, Array],
      default: ''
    },
    rules: {
      type: [Object, String],
      default: () => ({})
    },
    optionsLabel: {
      type: String,
      default: null
    },
    loading: Boolean,
    clearable: Boolean,
    infiniteScroll: Boolean,
    // deselectFromDropdown works correct only on multiple select, lib restrictions
    deselectFromDropdown: Boolean
  },
  emits: ['input', 'nextPage', 'search'],
  setup (props, { emit, refs }) {
    let observer = null

    const innerValue = computed({
      get: () => props.value,
      set: (value) => emit('input', value)
    })

    const innerOptions = computed(() => unref(props.options))

    const createObserver = () => {
      if (observer) return

      observer = new IntersectionObserver(observerCallback)
    }

    const disconnectObserver = () => {
      if (!observer) return

      observer.disconnect()
      observer = null
    }

    const observerCallback = ([{ isIntersecting, target }]) => {
      if (!isIntersecting || props.loading) return

      const ul = target.offsetParent
      const scrollTop = target.offsetParent.scrollTop
      emit('nextPage', false, async () => {
        await nextTick()
        ul.scrollTop = scrollTop
      })
    }

    const addOptionsObservation = async () => {
      if (!props.infiniteScroll) return

      if (!observer) createObserver()

      await nextTick()
      // TODO: rewrite after updating to Vue3
      observer.observe(refs.optionsListFooter)
    }

    const search = (value) => {
      emit('search', value)
    }

    watch(
      () => props.infiniteScroll,
      (value) => {
        if (value && !observer) createObserver()

        if (!value && observer) disconnectObserver()
      },
      { immediate: true }
    )

    return {
      innerValue,
      innerOptions,

      disconnectObserver,
      addOptionsObservation,
      search
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
