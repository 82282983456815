import { path } from '@/router/index'
import { useResource } from '@/services/resources'
import { httpStatuses } from '@/services/api'
import store from '@/store'

export const routerDataResolver = async (to, next, resource) => {
  const { can, callRequest } = useResource(resource)
  await store.dispatch('app/setHeadLoading', true)

  if (!can) next(path.forbidden)

  const [err, res] = await callRequest()

  await store.dispatch('app/setHeadLoading', false)

  if (err) {
    err.status === httpStatuses.notFound ? next(path.notFound) : next(path.forbidden)
  }

  // Mutate params object. Prop field 'prefetchResponse' is ready for retrieve data from View
  to.params.dataResolverResponse = res.data

  next()
}
