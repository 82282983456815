import { forEach, has, isEqual, isFunction } from 'lodash'

export const inputTypes = {
  text: 'text',
  number: 'number',
  select: 'select',
  checkbox: 'checkbox'
}

export const inputsByType = {
  [inputTypes.text]: 'VxInput',
  [inputTypes.number]: 'VxInput',
  [inputTypes.select]: 'VxSelect',
  [inputTypes.checkbox]: 'VxCheckbox'
}

export const defaultParserRules = {
  relatedFields: {},
  prefix: '',
  // now works only for one field, maybe rename
  messageForStatus: {}
}

export const setValuesToForm = (from, to) => {
  forEach(from, (value, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (to.hasOwnProperty(key)) {
      to[key] = value
    }
  })
}

export const getDifferingFields = (initial, current, mapper = {}) => {
  const res = {}

  forEach(current, (value, key) => {
    if (!has(initial, key)) return

    if (isEqual(value, initial[key])) return

    res[key] = has(mapper, key) && isFunction(mapper[key])
      ? mapper[key](value)
      : value
  })

  return res
}
