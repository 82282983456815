import { categories, skins } from '@/services/resources'

export const path = {
  items: {
    path: '/skin-items',
    name: 'skin-items-list',
    meta: {
      resources: [skins.getAll],
      pageTitle: 'Skin Items'
    }
  },
  categories: {
    path: '/categories',
    name: 'categories-list',
    meta: {
      pageTitle: 'Categories',
      resources: [categories.getAll]
    }
  },
  importSkins: {
    path: '/skins-import',
    name: 'skins-import',
    meta: {
      resources: [skins.checkNew],
      pageTitle: 'Import Skins'
    }
  }
}

export const skinsRoutes = [
  {
    ...path.items,
    component: () => import('@/views/skins/SkinItems.vue')
  },
  {
    ...path.categories,
    component: () => import('@/views/skins/SkinsCategories.vue')
  },
  {
    ...path.importSkins,
    component: () => import('@/views/skins/SkinsImport.vue')
  }
]
