import { bonuses } from '@/services/resources'

export const path = {
  moneyBonuses: {
    path: '/money-bonus',
    name: 'money-bonuses',
    meta: {
      pageTitle: 'Money Bonuses',
      resources: [bonuses.money]
    }
  },
  refillBonuses: {
    path: '/refill-bonus',
    name: 'refill-bonuses',
    meta: {
      pageTitle: 'Refill Bonuses',
      resources: [bonuses.refill]
    }
  },
  bonusUsages: {
    path: '/bonus-usages',
    name: 'bonus-usages',
    meta: {
      pageTitle: 'Bonus Usages',
      resources: [bonuses.usages]
    }
  }
}

export const bonusesRoutes = [
  {
    ...path.moneyBonuses,
    component: () => import('@/views/bonuses/MoneyBonuses')
  },
  {
    ...path.refillBonuses,
    component: () => import('@/views/bonuses/RefillBonuses')
  },
  {
    ...path.bonusUsages,
    component: () => import('@/views/bonuses/BonusUsages')
  }
]
