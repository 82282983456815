import { permissions } from '@/plugins/acl'
import api from '@/services/api'

const currenciesUrl = '/currencies'
const entityName = 'Currency'

export default {
  getAll: {
    permission: permissions.currencyView,
    request: ({ params }) => api.get(`${currenciesUrl}`, params)
  },
  getOne: {
    permission: permissions.currencyView,
    request: ({ urlParams: { id }, params }) => api.get(`${currenciesUrl}/${id}`, params)
  },
  updateExchangeRates: {
    permission: permissions.currencyUpdate,
    entity: 'Currencies',
    request: () => api.post(`${currenciesUrl}/update-exchange-rates`)
  },
  create: {
    permission: permissions.currencyCreate,
    entity: entityName,
    request: ({ data }) => api.post(`${currenciesUrl}`, data)
  },
  update: {
    permission: permissions.currencyUpdate,
    entity: entityName,
    request: ({ urlParams: { id }, data }) => api.put(`${currenciesUrl}/${id}`, data)
  },
  delete: {
    permission: permissions.currencyDelete,
    confirm: true,
    entity: entityName,
    request: ({ urlParams: { id } }) => api.delete(`${currenciesUrl}/${id}`)
  }
}
