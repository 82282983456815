import { upgrades } from '@/services/resources'

export const path = {
  upgrades: {
    path: '/upgrades',
    name: 'upgrade-list',
    meta: {
      resources: [upgrades.getAll],
      pageTitle: 'Upgrades'
    }
  }
}

export const upgradesRoutes = [
  {
    ...path.upgrades,
    component: () => import('@/views/upgrades/Upgrades.vue')
  }
]
