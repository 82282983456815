import { errorsType } from '@/services/errors'

export const path = {
  notFound: {
    path: '/error-404',
    name: 'error-404'
  },
  forbidden: {
    path: '/error-403',
    name: 'error-403'
  }
}

const component = () => import('@/views/error/Error')

export const errorsRoutes = [
  {
    ...path.notFound,
    component,
    meta: {
      type: errorsType.notFound
    }
  },
  {
    ...path.forbidden,
    component,
    meta: {
      type: errorsType.forbidden
    }
  }
]
