import { faq, notifications, contents, passDataToResource } from '@/services/resources'
import { routerDataResolver } from '@/router'

export const path = {
  faq: {
    path: '/faq',
    name: 'faq',
    meta: {
      pageTitle: 'FAQ',
      resources: [faq.getAll]
    }
  },
  notifications: {
    path: '/notifications',
    name: 'notifications',
    meta: {
      resources: [notifications.getAll],
      pageTitle: 'Notifications'
    }
  },
  contents: {
    path: '/content-management',
    name: 'content-list',
    meta: {
      resources: [contents.getAll],
      pageTitle: 'Content Translations'
    }
  },
  createContent: {
    path: '/content-management/new',
    name: 'create-content',
    meta: {
      pageTitle: 'Create Content',
      resources: [contents.create]
    }
  },
  updateContent: {
    path: '/content-management/:id',
    name: 'content-update',
    meta: {
      pageTitle: 'Update Content',
      resources: [
        contents.getOne,
        contents.update
      ]
    }
  }
}

export const textsRoutes = [
  {
    ...path.faq,
    component: () => import('@/views/texts/FAQ')
  },
  {
    ...path.notifications,
    component: () => import('@/views/texts/Notifications')
  },
  {
    ...path.contents,
    component: () => import('@/views/texts/ContentTranslations.vue')
  },
  {
    ...path.createContent,
    component: () => import('@/views/texts/ContentEditor')
  },
  {
    ...path.updateContent,
    component: () => import('@/views/texts/ContentEditor'),
    props: true,
    beforeEnter: (to, from, next) => {
      return routerDataResolver(
        to,
        next,
        passDataToResource(contents.getOne, {
          requestParams: {
            urlParams: { id: to.params.id },
            params: { include: 'translations' }
          }
        })
      )
    }
  }
]
