import api from '@/services/api'
import { permissions } from '@/plugins/acl'

const translationsUrl = '/translations'

export default {
  getAvailableLocales: {
    permission: permissions.translationView,
    request: ({ params }) => api.get(`${translationsUrl}/available-locales`, params)
  }
}
