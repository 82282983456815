import { reactive, ref, watch } from '@vue/composition-api'
import { useStore } from '@vueblocks/vue-use-vuex'
import { httpStatuses } from '@/services/api'
import { auth, passDataToResource } from '@/services/resources'
import { useToast } from '@/plugins/toastification'

export default function useLogin ({ skipDispatcher }) {
  const store = useStore()
  const toast = useToast()
  const incorrectCredentials = ref(false)

  const formValue = reactive({
    login: '',
    password: '',
    code: ''
  })
  const rules = {
    login: {
      required: true,
      max: 255
    },
    password: {
      required: true,
      min: 6,
      max: 255
    },
    code: {
      required: true,
      length: 6,
      integer: true
    }
  }

  watch(formValue, () => {
    if (incorrectCredentials.value) {
      incorrectCredentials.value = false
    }
  })

  const frontToBackMapper = ({ login, password, code }) => {
    return { login, password, '2fa_code': code }
  }

  const resource = passDataToResource(auth.login, { frontToBackMapper })

  const parserRules = {
    relatedFields: { '2fa_code': 'code' },
    messageForStatus: {
      status: httpStatuses.forbidden,
      field: 'code',
      msg: 'Verification code is incorrect'
    }
  }

  const handleSubmit = async ([error, res]) => {
    if (error?.status === httpStatuses.unauthorized) {
      incorrectCredentials.value = true
    }

    if (res) {
      if (!skipDispatcher) {
        await store.dispatch('user/login', res.data)
      } else {
        await store.dispatch('user/loginModal', res.data)
      }
    }
  }

  return {
    rules,
    formValue,
    incorrectCredentials,
    parserRules,
    resource,

    handleSubmit
  }
}
