import { permissions } from '@/plugins/acl'
import api from '@/services/api'

export default {
  getAll: {
    permission: permissions.tagView,
    request: ({ params }) => api.get('/tags', params)
  },
  visibilities: () => api.get('/tags/visibilities'),
  create: {
    permission: permissions.tagCreate,
    entity: 'Tag',
    request: ({ data }) => api.post(`/tags`, data)
  },
  update: {
    permission: permissions.tagUpdate,
    entity: 'Tag',
    request: ({ urlParams: { id }, data }) => {
      return api.put(`/tags/${id}`, data)
    }
  },
  delete: {
    permission: permissions.tagDelete,
    entity: 'Tag',
    confirm: true,
    request: ({ urlParams: { id } }) => api.delete(`/tags/${id}`)
  }
}
