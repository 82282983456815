import { $themeConfig } from '@themeConfig'
import store from '@/store'
import { computed } from '@vue/composition-api'

const useSkin = () => {
  const storageKey = 'skin'

  const skin = computed({
    get: () => store.state.appConfig.layout.skin || skins.bordered,
    set: (value) => setSkin(value)
  })

  const skins = {
    dark: 'dark',
    bordered: 'bordered'
  }

  const skinsMap = {
    [skins.dark]: {
      icon: 'SunIcon',
      bodyClass: 'dark-layout',
      nextSkin: skins.bordered
    },
    [skins.bordered]: {
      icon: 'MoonIcon',
      nextSkin: skins.dark
    }
  }

  const currentSkinFromMap = computed(() => {
    return skinsMap[skin.value] || skinsMap[skins.bordered]
  })

  const nextSkin = () => {
    skin.value = currentSkinFromMap.value.nextSkin
  }

  const initSkin = () => {
    const skin = getSkinFromStorage() || $themeConfig.layout.skin || skins.bordered

    setSkin(skin)
  }

  const setSkin = (newSkin) => {
    store.commit('appConfig/UPDATE_SKIN', newSkin)

    localStorage.setItem(storageKey, newSkin)

    // Update DOM for dark-layout
    if (newSkin === skins.dark) {
      document.body.classList.add(skinsMap.dark.bodyClass)
    } else if (document.body.className.match(skinsMap.dark.bodyClass)) {
      document.body.classList.remove(skinsMap.dark.bodyClass)
    }
  }

  const getSkinFromStorage = () => {
    return localStorage.getItem(storageKey)
  }

  return {
    skin,
    skins,
    skinsMap,
    currentSkinFromMap,
    storageKey,
    initSkin,
    setSkin,
    nextSkin
  }
}

export default useSkin
