<template>
  <b-card class="mb-0">
    <div class="brand-logo">
      <img
        class="mw-100 w-50"
        src="@/assets/images/logo/logo-full.png"
        alt="CSBro Logo"
      >
    </div>

    <vx-form
      v-slot="{ loading }"
      class="auth-login-form mt-2"
      :resource="resource"
      :parser-rules="parserRules"
      @submit="handleSubmit"
    >
      <vx-input
        v-model="formValue.login"
        name="login"
        label="Login"
        :rules="rules.login"
      />

      <vx-input
        v-model="formValue.password"
        name="password"
        label="Password"
        type="password"
        :rules="rules.password"
      />

      <vx-input
        v-model="formValue.code"
        name="code"
        label="2FA Code"
        :rules="rules.password"
      />

      <div
        v-if="incorrectCredentials"
        class="text-danger mb-1"
      >
        Incorrect login or password
      </div>

      <vx-button
        :loading="loading"
        variant="primary"
        type="submit"
        block
      >
        Sign in
      </vx-button>
    </vx-form>
  </b-card>
</template>

<script>
import useLogin from '@/views/login/useLogin'
import { BCard } from 'bootstrap-vue'
import { VxForm, VxInput } from '@/components/form'
import { VxButton } from '@/components/buttons'

export default {
  name: 'LoginForm',
  components: {
    VxButton,
    VxForm,
    VxInput,
    BCard
  },
  props: {
    skipDispatcher: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    return useLogin(props)
  }
}
</script>
