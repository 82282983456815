import { passDataToResource, payments, paymentSystems } from '@/services/resources'
import { routerDataResolver } from '@/router'

export const path = {
  payments: {
    path: '/payments-history',
    name: 'payments-list',
    meta: {
      resources: [payments.getAll],
      pageTitle: 'Payments History'
    }
  },
  paymentSystems: {
    path: '/payment-settings',
    name: 'payment-settings',
    meta: {
      pageTitle: 'Gateway Settings',
      resources: [paymentSystems.getAll]
    }
  },
  paymentSystemUpdate: {
    path: '/payment-settings/:id',
    name: 'payment-settings-edit',
    meta: {
      pageTitle: 'Update Gateway',
      resources: [
        paymentSystems.getOne,
        paymentSystems.updateGateway
      ]
    }
  },
  paymentCountries: {
    path: '/payment-countries',
    name: 'payment-countries',
    meta: {
      resources: [paymentSystems.getCountries],
      pageTitle: 'Gateway Countries'
    }
  }
}

export const paymentsRoutes = [
  {
    ...path.payments,
    component: () => import('@/views/payments/Payments.vue')
  },
  {
    ...path.paymentSystems,
    component: () => import('@/views/payments/PaymentSettings.vue')
  },
  {
    ...path.paymentSystemUpdate,
    component: () => import('@/views/payments/PaymentSettingsEditor'),
    props: true,
    beforeEnter: (to, from, next) => {
      return routerDataResolver(
        to,
        next,
        passDataToResource(paymentSystems.getOne, {
          requestParams: { urlParams: { id: to.params.id } }
        })
      )
    }
  },
  {
    ...path.paymentCountries,
    component: () => import('@/views/payments/PaymentCountries')
  }
]
