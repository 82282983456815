import api from '@/services/api'
import { permissions } from '@/plugins/acl'

const contenstUrl = '/contents'
const entityName = 'Content'

export default {
  getAll: {
    permission: permissions.contentView,
    request: ({ params }) => api.get(contenstUrl, params)
  },
  getOne: {
    permission: permissions.contentView,
    request: ({ urlParams: { id }, params }) => api.get(`${contenstUrl}/${id}`, params)
  },
  getTypes: {
    permission: permissions.contentView,
    request: () => api.get(`${contenstUrl}/types`)
  },
  create: {
    permission: permissions.contentCreate,
    entity: entityName,
    request: ({ data }) => api.post(contenstUrl, data)
  },
  update: {
    permission: permissions.contentUpdate,
    entity: entityName,
    request: ({ urlParams: { id }, data }) => api.put(`${contenstUrl}/${id}`, data)
  },
  delete: {
    permission: permissions.contentDelete,
    confirm: true,
    entity: entityName,
    request: ({ urlParams: { id } }) => api.delete(`${contenstUrl}/${id}`)
  }
}
