export const path = {
  home: {
    path: '/',
    name: 'home'
  }
}

export const homeRoutes = [
  {
    ...path.home,
    component: () => import('@/views/home/Home.vue')
  }
]
