import { cases } from '@/services/resources'
import { routerDataResolver } from '@/router'
import { chestDataResolverResource } from '@/views/cases/case/caseDataResolver'

export const path = {
  cases: {
    path: '/chests',
    name: 'cases',
    meta: {
      pageTitle: 'Cases',
      resources: [cases.getAll]
    }
  },
  createCase: {
    path: '/chest-create',
    name: 'chest-create',
    meta: {
      resources: [cases.create],
      pageTitle: 'Create Case'
    }
  },
  caseDetails: {
    path: '/chests/:id',
    append: '',
    name: 'case-details',
    meta: {
      pageTitle: 'Case',
      resources: [
        cases.getOne,
        cases.update
      ]
    }
  },
  caseContentBuilder: {
    path: '/chests/:id/content-builder',
    append: 'content-builder',
    name: 'case-builder',
    meta: {
      pageTitle: 'Case Content Builder',
      resources: [
        cases.getOne,
        cases.categories,
        cases.updateCategories
      ]
    }
  },
  casePFSettings: {
    path: '/chests/:id/pf-case-settings',
    append: 'pf-case-settings',
    name: 'pf-case-settings',
    meta: {
      pageTitle: 'PF case settings',
      resources: [
        cases.getOne,
        cases.borders
      ]
    }
  },
  caseItemsSettings: {
    path: '/chests/:id/items-settings',
    append: 'items-settings',
    name: 'items-settings',
    meta: {
      pageTitle: 'Case items',
      resources: [
        cases.getOne,
        cases.categories,
        cases.items
      ]
    }
  },
  caseGeneratedContent: {
    path: '/chests/:id/generated-content/:generation_id?',
    append: 'generated-content/:generation_id?',
    name: 'generated-content',
    meta: {
      pageTitle: 'Generated Content',
      resources: [
        cases.getOne,
        cases.createGeneration,
        cases.getGeneration,
        cases.getGenerationBorders,
        cases.getGenerationContent,
        cases.getGenerationCategories
      ]
    }
  },
  caseContentHistory: {
    path: '/chests/:id/content-history',
    append: 'content-history',
    name: 'content-history',
    meta: {
      pageTitle: 'Case Content History',
      resources: [
        cases.getOne,
        cases.getAllGenerations
      ]
    }
  },
  caseOpens: {
    path: '/chest-openings',
    name: 'chests-drop-list',
    meta: {
      resources: [cases.opens],
      pageTitle: 'Opens list'
    }
  }
}

export const casesRoutes = [
  {
    ...path.cases,
    component: () => import('@/views/cases/Cases')
  },
  {
    ...path.caseOpens,
    component: () => import('@/views/cases/CasesOpens.vue')
  },
  {
    ...path.createCase,
    component: () => import('@/views/cases/case-new-editor/CaseNewEditor.vue')
  },
  {
    ...path.caseDetails,
    component: () => import('@/views/cases/case/details/CaseDetails.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      const include = ['type', 'lastGeneration', 'lastActiveGeneration', 'options']
      return routerDataResolver(
        to,
        next,
        chestDataResolverResource(to.params.id, include)
      )
    }
  },
  {
    ...path.caseContentBuilder,
    component: () => import('@/views/cases/case/content-builder/ContentBuilder.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      return routerDataResolver(
        to,
        next,
        chestDataResolverResource(to.params.id)
      )
    }
  },
  {
    ...path.caseItemsSettings,
    component: () => import('@/views/cases/case/items-settings/ItemsSettings.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      return routerDataResolver(
        to,
        next,
        chestDataResolverResource(to.params.id)
      )
    }
  },
  {
    ...path.casePFSettings,
    component: () => import('@/views/cases/case/provably-fair/ProvablyFair.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      return routerDataResolver(
        to,
        next,
        chestDataResolverResource(to.params.id)
      )
    }
  },
  {
    ...path.caseGeneratedContent,
    component: () => import('@/views/cases/case/generated-content/GeneratedContent.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      const include = ['lastGeneration']
      return routerDataResolver(
        to,
        next,
        chestDataResolverResource(to.params.id, include)
      )
    }
  },
  {
    ...path.caseContentHistory,
    component: () => import('@/views/cases/case/generation-history/GenerationHistory.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      return routerDataResolver(
        to,
        next,
        chestDataResolverResource(to.params.id)
      )
    }
  },
  {
    ...path.caseOpens,
    component: () => import('@/views/cases/CasesOpens.vue')
  }
]
