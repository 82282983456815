import { permissions } from '@/plugins/acl'
import api from '@/services/api'

const sectionsPath = '/sections'
const entity = 'Section'

export default {
  getAll: {
    permission: permissions.sectionView,
    request: ({ params }) => api.get(sectionsPath, params)
  },
  getOne: {
    permission: permissions.sectionView,
    request: ({ urlParams: { id }, data }) => api.get(`${sectionsPath}/${id}`, data)
  },
  create: {
    permission: permissions.sectionCreate,
    entity,
    request: ({ data }) => api.post(sectionsPath, data)
  },
  update: {
    permission: permissions.sectionUpdate,
    entity,
    request: ({ urlParams: { id }, data }) => api.put(`${sectionsPath}/${id}`, data)
  },
  delete: {
    permission: permissions.sectionDelete,
    entity,
    confirm: true,
    request: ({ urlParams: { id } }) => api.delete(`${sectionsPath}/${id}`)
  },
  updatePositions: {
    permission: permissions.sectionUpdatePositions,
    entity,
    request: ({ data }) => api.put(`${sectionsPath}/positions`, data)
  },
  getCases: {
    permission: permissions.sectionView,
    request: ({ urlParams: { id } }) => api.get(`${sectionsPath}/${id}/chests`)
  },
  updateCases: {
    permission: permissions.sectionUpdate,
    entity: 'Section cases',
    request: ({ urlParams: { id }, data }) => api.put(`${sectionsPath}/${id}/sync-chests`, data)
  }
}
