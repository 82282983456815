<template>
  <b-modal
    ref="modal"
    centered
    hide-header
    hide-footer
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
  >
    <template #modal-title>
      Login
    </template>
    <login-form :skip-dispatcher="true" />
  </b-modal>
</template>

<script>
import { ref, watchEffect, onMounted } from '@vue/composition-api'
import { BModal } from 'bootstrap-vue'
import LoginForm from '@/views/login/LoginForm'
import store from '@/store'

export default {
  name: 'LoginModal',
  components: { BModal, LoginForm },
  setup () {
    const modal = ref(null)

    onMounted(() => {
      watchEffect(() => {
        store.state.user.loginModal ? modal.value.show() : modal.value.hide()
      })
    })

    return {
      modal
    }
  }
}
</script>
<style scoped>
>>> .modal .modal-body {
  padding: 0;
}
</style>
