import { antifraud } from '@/services/resources'

export const path = {
  antifraudRecords: {
    path: '/antifraud/records',
    name: 'antifraud-records',
    meta: {
      resources: [antifraud.getAllRecords],
      pageTitle: 'Players'
    }
  },
  antifraudActivations: {
    path: '/antifraud/activations',
    name: 'antifraud-activations',
    meta: {
      resources: [antifraud.getAllActivations],
      pageTitle: 'Activations'
    }
  },
  antifraudCredentials: {
    path: '/antifraud/credentials',
    name: 'antifraud-credentials',
    meta: {
      resources: [antifraud.getAllCredentials],
      pageTitle: 'Credentials'
    }
  }
}

export const antifraudRoutes = [
  {
    ...path.antifraudRecords,
    component: () => import('@/views/antifraud/Records')
  },
  {
    ...path.antifraudActivations,
    component: () => import('@/views/antifraud/Activations')
  },
  {
    ...path.antifraudCredentials,
    component: () => import('@/views/antifraud/Credentials')
  }
]
