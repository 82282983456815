import api from '@/services/api'
import { permissions } from '@/plugins/acl'

const promocodesUrl = '/promocodes'
const promocodeUsage = '/promocode-usages'

export default {
  getAll: {
    permission: permissions.promocodeView,
    request: ({ params }) => api.get(promocodesUrl, params)
  },
  getOne: {
    permission: permissions.promocodeView,
    request: ({ urlParams: { id }, params }) => api.get(`${promocodesUrl}/${id}`, params)
  },
  usages: {
    permission: permissions.promocodeUsageView,
    request: ({ params }) => api.get(promocodeUsage, params)
  },
  create: {
    permission: permissions.promocodeCreate,
    request: ({ data }) => api.post(promocodesUrl, data)
  },
  update: {
    permission: permissions.promocodeUpdate,
    request: ({ urlParams: { id }, data }) => api.put(`${promocodesUrl}/${id}`, data)
  },
  updateTags: {
    permission: permissions.promocodeTagsUpdate,
    request: ({ urlParams: { id }, data }) => api.post(`${promocodesUrl}/${id}/tags`, data)
  }
}
