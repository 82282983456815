<template>
  <validation-provider
    v-slot="{ errors, valid }"
    :rules="rules"
    :name="name"
    :vid="name"
    slim
  >
    <b-form-group>
      <label v-if="label" :for="name">
        {{ label }}
      </label>

      <b-input-group
        :append="appendText"
      >
        <b-form-input
          :id="name"
          v-model="innerValue"
          v-bind="$attrs"
          :type="type"
        />

        <b-input-group-append
          v-if="hasAppendBlock"
          :class="{'nested-pa': $slots.append}"
          is-text
        >
          <slot v-if="$slots.append" name="append" />

          <feather-icon
            v-if="clearable"
            icon="XIcon"
            class="cursor-pointer"
            @click="clear"
          />
        </b-input-group-append>
      </b-input-group>

      <small v-if="$slots.message">
        <slot name="message" />
      </small>

      <small v-if="!valid" class="text-danger">
        {{ errors && errors[0] }}
      </small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { computed } from '@vue/composition-api'
import { BFormGroup, BFormInput, BInputGroupAppend, BInputGroup } from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput
  },
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    name: {
      type: [String],
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    rules: {
      type: [Object, String],
      default: () => ({})
    },
    label: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    },
    appendText: {
      type: String,
      default: ''
    },
    formatterSetter: {
      type: Function,
      default: null
    },
    formatterGetter: {
      type: Function,
      default: null
    }
  },
  emits: ['input'],
  setup (props, { emit, slots }) {
    const { clearable, formatterSetter, formatterGetter } = props

    // TODO: Add password toggle to hasAppendBlock
    const hasAppendBlock = !!slots.append || clearable

    const innerValue = computed({
      get: () => {
        if (formatterGetter) {
          return props.value ? formatterGetter(props.value) : null
        }
        return props.value
      },
      set: (value) => {
        if (formatterSetter) {
          emit('input', formatterSetter(value))
        } else {
          emit('input', value)
        }
      }
    })

    const clear = () => {
      emit('input', '')
    }

    return {
      innerValue,

      hasAppendBlock,
      clear
    }
  }
}
</script>
<style lang="scss" scoped>
.nested-pa {
  .input-group-text {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
