export const httpStatuses = {
  ok: 200,
  created: 201,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  notAllowed: 405,
  requestTimeout: 408,
  failedValidation: 422
}

export const httpMethods = {
  get: 'get',
  post: 'post',
  put: 'put',
  patch: 'patch',
  delete: 'delete'
}
