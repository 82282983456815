import { exchanges } from '@/services/resources'

export const path = {
  exchanges: {
    path: '/exchanges',
    name: 'exchange-list',
    meta: {
      pageTitle: 'Exchanges',
      resources: [exchanges.getAll]
    }
  }
}

export const exchangesRoutes = [
  {
    ...path.exchanges,
    component: () => import('@/views/exchanges/Exchanges')
  }
]
