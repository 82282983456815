import { passDataToResource, promocodes } from '@/services/resources'
import { routerDataResolver } from '@/router'

export const path = {
  promocodes: {
    path: '/promocodes',
    name: 'promocodes-list',
    meta: {
      pageTitle: 'Promocodes',
      resources: [promocodes.getAll]
    }
  },
  createPromocode: {
    path: '/promocodes/new',
    name: 'create-promocode',
    meta: {
      pageTitle: 'Create Promocode',
      resources: [promocodes.create]
    }
  },
  promocodeDetails: {
    path: '/promocodes/:id',
    name: 'promocode-details',
    meta: {
      resources: [promocodes.getOne],
      pageTitle: 'Promocode Details'
    }
  },
  promocodeUsages: {
    path: '/promocode-usages',
    name: 'promocode-usages',
    meta: {
      pageTitle: 'Promocode Usages',
      resources: [promocodes.usages]
    }
  }
}

export const promocodesRoutes = [
  {
    ...path.promocodes,
    component: () => import('@/views/promocodes/Promocodes')
  },
  {
    ...path.createPromocode,
    component: () => import('@/views/promocodes/PromocodeEditor')
  },
  {
    ...path.promocodeDetails,
    component: () => import('@/views/promocodes/PromocodeEditor'),
    props: true,
    beforeEnter: (to, from, next) => {
      return routerDataResolver(
        to,
        next,
        passDataToResource(promocodes.getOne, {
          requestParams: {
            urlParams: { id: to.params.id },
            params: { include: 'tags' }
          }
        })
      )
    }
  },
  {
    ...path.promocodeUsages,
    component: () => import('@/views/promocodes/PromocodeUsages')
  }
]
