import { has, isEmpty, isNumber, isObject, isString } from 'lodash'
import getSymbolFromCurrency from 'currency-symbol-map'
import currency from 'currency.js'

const mainCurrencyCode = process.env.VUE_APP_MAIN_CURRENCY || 'USD'
const brcCurrency = 'BRC'

const formatToDecimal = (value) => {
  return currency(value, { fromCents: true }).value
}

const formatToCents = value => {
  return currency(value).intValue
}

const isAmountStructure = (value) => {
  return isObject(value) && has(value, 'amount') && has(value, 'currency')
}

const moneyValidator = (value) => {
  return isNumber(value) || (isString(value) && value !== '')
}

const isValidMoney = (money) => {
  return isAmountStructure(money)
    ? moneyValidator(money.amount)
    : moneyValidator(money)
}

const buildMoney = (amount, currency = mainCurrencyCode) => {
  if (!isNumber(amount)) return null

  return { amount, currency }
}

const getCurrencySymbol = (currency = mainCurrencyCode) => {
  const symbol = getSymbolFromCurrency(currency)
  return symbol || currency
}

const moneyFormat = (money, { withSpace = true } = {}) => {
  if (!isValidMoney(money)) return null

  const {
    amount,
    currency: currencyCode
  } = isAmountStructure(money)
    ? money
    : { amount: money, currency: mainCurrencyCode }

  if (currencyCode === brcCurrency) return `${formatToDecimal(amount)} ${currencyCode}`

  const currencyConfig = {
    symbol: getSymbolFromCurrency(currencyCode) || '',
    fromCents: true,
    pattern: withSpace ? '! #' : '!#'
  }
  return currency(amount, currencyConfig).format()
}

const getMainPrice = (value = []) => {
  return !isEmpty(value) ? value.find(item => item.currency === mainCurrencyCode).amount : 0
}

export {
  mainCurrencyCode,
  brcCurrency,
  formatToDecimal,
  formatToCents,
  isAmountStructure,
  isValidMoney,
  buildMoney,
  moneyFormat,
  getCurrencySymbol,
  getMainPrice
}
