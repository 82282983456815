import { tokenService } from '@/services/auth'
import { setPermissions } from '@/plugins/acl/ability'
import router, { path } from '@/router'
import { isEmpty } from 'lodash'

export default {
  namespaced: true,

  state: {
    loaded: false,
    headLoading: false,
    windowWidth: 0
  },

  mutations: {
    updateWindowWidth (state, val) {
      state.windowWidth = val
    },

    // TODO: rename for correct understanding field name
    loaded (state) {
      state.loaded = true
    },

    headLoading (state, val) {
      state.headLoading = val
    }
  },

  actions: {
    async init ({ dispatch, commit, state }) {
      if (!tokenService.hasTokens()) {
        commit('loaded')
        return
      }

      const [err, res] = await dispatch('user/fetchUser', state, { root: true })

      commit('loaded')

      if (err) return router.push(path.forbidden)

      if (!res.data.role || isEmpty(res.data.role.permissions)) {
        return router.push(path.forbidden)
      }

      setPermissions(res.data.role.permissions)
    },
    async setHeadLoading ({ commit }, value) {
      commit('headLoading', value)
    }
  }
}
