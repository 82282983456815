import { isEmpty, isEqual, mapValues, toString } from 'lodash'
import router from '@/router'
import { reactive, toRefs, watch } from '@vue/composition-api'
import { appInstance } from '@/services/app'
import { useCan } from '@/plugins/acl'

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
const useRouter = () => {
  const vm = appInstance()
  const state = reactive({
    route: vm.$route
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    }
  )

  return { ...toRefs(state), router: vm.$router }
}

const isRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.name === router.currentRoute.name
}

const getRouteIdParam = () => {
  return router.currentRoute?.params?.id
}

const getRedirectQuery = ({ path, fullPath }) => {
  const skipRoutesForRedirecting = ['/login', '/']

  return skipRoutesForRedirecting.includes(path)
    ? {}
    : { to: fullPath }
}

const buildRoute = ({ name, meta = {} } = {}, attrs = {}) => {
  if (!name) return null

  const { params = {}, query = {} } = attrs

  const formattedParams = mapValues(params, String)
  const formattedQuery = mapValues(query, String)

  return {
    name,
    can: canRoute(meta?.resources),
    params: formattedParams,
    query: formattedQuery
  }
}

const canRoute = (resources = []) => {
  if (isEmpty(resources)) return true

  const permissions = resources.map(({ permission }) => {
    return permission ? useCan(permission) : true
  })

  return permissions.includes(true)
}

const routerQueryIsEqualWith = (value = {}) => {
  const valueFormattedToString = mapValues(value, toString)
  const queryFormattedToString = mapValues(router.currentRoute.query || {}, toString)
  return isEqual(valueFormattedToString, queryFormattedToString)
}

export {
  useRouter,
  isRouteActive,
  getRouteIdParam,
  getRedirectQuery,
  buildRoute,
  routerQueryIsEqualWith
}
