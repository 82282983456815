<template>
  <b-progress
    v-if="headLoading"
    class="header-progress-bar"
    animated
    :value="100"
    variant="primary"
  />
</template>

<script>
import { BProgress } from 'bootstrap-vue'
import { useVuex } from '@vueblocks/vue-use-vuex'

export default {
  name: 'HeaderProgressBar',
  components: { BProgress },
  setup () {
    const { useState } = useVuex('app')
    const { headLoading } = useState(['headLoading'])

    return { headLoading }
  }
}
</script>

<style lang="scss" scoped>
.header-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
  height: 0.35rem;

  &::v-deep {
    .progress-bar {
      border-radius: 0;
    }
  }
}
</style>
