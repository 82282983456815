import api from '@/services/api'
import { permissions } from '@/plugins/acl'

const entity = 'Case'
const casesPath = '/chests'

export default {
  getAll: {
    permission: permissions.chestView,
    request: ({ params }) => api.get(casesPath, params)
  },
  getOne: {
    permission: permissions.chestView,
    request: ({ urlParams: { id }, params }) => api.get(`${casesPath}/${id}`, params)
  },
  create: {
    permission: permissions.chestCreate,
    entity,
    request: ({ data }) => api.post(casesPath, data)
  },
  update: {
    permission: permissions.chestUpdate,
    entity,
    request: ({ urlParams: { id }, data }) => {
      return api.put(`${casesPath}/${id}`, data)
    }
  },
  labels: {
    permission: permissions.chestView,
    request: () => api.get(`${casesPath}/labels`)
  },
  statusToggle: {
    permission: permissions.chestUpdate,
    entity,
    request: ({ urlParams: { id, status } }) => {
      return api.patch(`${casesPath}/${id}/${status}`)
    }
  },
  opens: {
    permission: permissions.chestOpeningView,
    request: ({ params }) => api.get('/chest-openings', params)
  },
  types: {
    permission: permissions.chestView,
    request: () => api.get('/chest-types')
  },
  categories: {
    permission: permissions.chestCategoryView,
    request: ({ params, urlParams: { id } }) => {
      return api.get(`${casesPath}/${id}/categories`, params)
    }
  },
  updateCategories: {
    permission: permissions.chestCategoryUpdate,
    entity: 'Category',
    request: ({ urlParams: { id }, data }) => {
      return api.put(`${casesPath}/${id}/categories`, data)
    }
  },
  items: {
    permission: permissions.chestItemView,
    request: ({ params, urlParams: { id } }) => {
      return api.get(`${casesPath}/${id}/items`, params)
    }
  },
  updateItem: {
    permission: permissions.chestItemUpdate,
    entity: 'Item',
    request: ({ urlParams: { chestId, itemId }, data }) => {
      return api.put(`${casesPath}/${chestId}/items/${itemId}`, data)
    }
  },
  borders: {
    permission: permissions.chestBorderView,
    request: ({ params, urlParams: { id } }) => {
      return api.get(`${casesPath}/${id}/borders`, params)
    }
  },
  bordersSettings: {
    permission: permissions.chestBorderView,
    request: ({ urlParams: { id } }) => {
      return api.get(`${casesPath}/${id}/borders/settings`)
    }
  },
  createBorder: {
    permission: permissions.chestBorderCreate,
    entity: 'Border',
    request: ({ urlParams: { id }, data }) => {
      return api.post(`${casesPath}/${id}/borders`, data)
    }
  },
  updateBorder: {
    permission: permissions.chestBorderUpdate,
    entity: 'Border',
    request: ({ urlParams: { chestId, borderId }, data }) => {
      return api.put(`${casesPath}/${chestId}/borders/${borderId}`, data)
    }
  },
  deleteBorder: {
    permission: permissions.chestBorderDelete,
    entity: 'Border',
    confirm: true,
    request: ({ urlParams: { chestId, borderId } }) => {
      return api.delete(`${casesPath}/${chestId}/borders/${borderId}`)
    }
  },
  getAllGenerations: {
    permission: permissions.chestGenerationView,
    request: ({ params, urlParams: { id } }) => {
      return api.get(`${casesPath}/${id}/generations`, params)
    }
  },
  createGeneration: {
    permission: permissions.chestGenerationCreate,
    entity: 'Generation',
    request: ({ urlParams: { id }, data }) => {
      return api.post(`${casesPath}/${id}/generations`, data)
    }
  },
  getGeneration: {
    permission: permissions.chestGenerationView,
    request: ({ params, urlParams: { chestId, generationId } }) => {
      return api.get(`${casesPath}/${chestId}/generations/${generationId}`, params)
    }
  },
  getGenerationBorders: {
    permission: permissions.chestGenerationView,
    request: ({ urlParams: { chestId, generationId } }) => {
      return api.get(`${casesPath}/${chestId}/generations/${generationId}/borders`)
    }
  },
  getGenerationContent: {
    permission: permissions.chestGenerationView,
    request: ({ params, urlParams: { chestId, generationId } }) => {
      return api.get(`${casesPath}/${chestId}/generations/${generationId}/contents`, params)
    }
  },
  getGenerationCategories: {
    permission: permissions.chestGenerationView,
    request: ({ params, urlParams: { chestId, generationId } }) => {
      return api.get(`${casesPath}/${chestId}/generations/${generationId}/categories`, params)
    }
  }
}
