<template>
  <vx-button
    icon="CopyIcon"
    size="sm"
    v-bind="$attrs"
    @click="copy"
  >
    <slot />
  </vx-button>
</template>

<script>
import { VxButton } from '@/components/buttons'
import { useToast } from '@/plugins/toastification'
import { isString } from 'lodash'

export default {
  name: 'VxButtonCopy',
  components: { VxButton },
  props: {
    textToCopy: {
      type: [String, Object, Array],
      required: true
    }
  },
  setup ({ textToCopy }) {
    const toast = useToast()

    const copy = () => {
      isString(textToCopy)
        ? navigator.clipboard.writeText(textToCopy)
        : navigator.clipboard.writeText(JSON.stringify(textToCopy))

      toast.success('Copied')
    }

    return {
      copy
    }
  }
}
</script>
