var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"rules":_vm.rules,"name":_vm.name,"vid":_vm.name,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-group',{staticClass:"wrapper"},[(_vm.label)?_c('label',{attrs:{"for":_vm.name}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('b-input-group',[_c('date-picker',_vm._b({staticClass:"w-100",attrs:{"id":_vm.name,"type":_vm.type,"format":_vm.format,"editable":false,"lang":_vm.datePickerLang,"range-separator":" - ","range":""},on:{"input":_vm.emitValue},scopedSlots:_vm._u([(_vm.sidebarShortcuts)?{key:"sidebar",fn:function(ref){
var emit = ref.emit;
var value = ref.value;
return _vm._l((_vm.sidebarShortcuts),function(ref,key){
var text = ref.text;
var range = ref.range;
return _c('button',{key:key,class:_vm.shortcutClasses(range, value),on:{"click":function($event){return emit(range)}}},[_vm._v(" "+_vm._s(text)+" ")])})}}:null],null,true),model:{value:(_vm.innerRange),callback:function ($$v) {_vm.innerRange=$$v},expression:"innerRange"}},'date-picker',_vm.$attrs,false)),(_vm.rangeDuration)?_c('small',[_vm._v(" "+_vm._s(_vm.rangeDuration)+" ")]):_vm._e()],1),(!valid)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors && errors[0])+" ")]):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }