import { cases, passDataToResource } from '@/services/resources'

export const chestDataResolverResource = (id, include) => {
  return passDataToResource(cases.getOne, {
    requestParams: {
      urlParams: { id },
      params: include && { include: include.join(',') }
    }
  })
}
