import axios from 'axios'
import qs from 'qs'
import { isArray } from 'lodash'
import { httpMethods } from '@/services/api/consts'

export default class HttpService {
  axios

  constructor (options) {
    const { baseUrl: baseURL } = options

    this.axios = axios.create({
      baseURL,
      headers: { 'Content-Type': 'application/json' },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'indices' })
      }
    })

    this.axios.interceptors.response.use(
      (response) => {
        const statuses = [200, 201]
        return statuses.includes(response.status) ? response.data : response
      }
    )
  }

  async _request (method, { url, data, params }) {
    try {
      const response = await this.axios.request({ method, url, data, params })

      return [null, { data: response, config: { method } }]
    } catch (error) {
      const parsedError = this._handleFailedResponse(error)
      return [parsedError, null]
    }
  }

  /**
   * @param {Object} error
   * @param {Object} error.config
   * @param {string=} error.code
   * @param {Object=} error.request
   * @param {Object=} error.response
   * @param {boolean} error.isAxiosError
   * @param {function} error.toJSON
   * @private
   */
  _handleFailedResponse (error) {
    if (error.response) {
      /**
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       * For statuses 4xx 5xx
       */
      console.error(error.response)
      return error.response
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.error(error.request)
      return error.request
    } else {
      return error.toJSON()
      // Something happened in setting up the request and triggered an Error
    }
  }

  get (url, params) {
    return this._request(httpMethods.get, { url, params })
  }

  post (url, data) {
    return this._request(httpMethods.post, { url, data })
  }

  put (url, data) {
    return this._request(httpMethods.put, { url, data })
  }

  patch (url, data) {
    return this._request(httpMethods.patch, { url, data })
  }

  delete (url) {
    return this._request(httpMethods.delete, { url })
  }

  isValidResponseStructure (response) {
    return isArray(response) && response.length === 2
  }
}
