import HttpService from '@/services/api/httpService'
import { httpStatuses } from '@/services/api/consts'

const api = new HttpService({
  baseUrl: process.env.VUE_APP_BASE_URL + '/admin'
})

export default api
export {
  httpStatuses
}
