import { passDataToResource, sections } from '@/services/resources'
import { routerDataResolver } from '@/router'

export const path = {
  sections: {
    path: '/chests-sections',
    name: 'cases-sections',
    meta: {
      pageTitle: 'Cases Sections',
      resources: [sections.getAll]
    }
  },
  sectionNew: {
    path: '/chests-sections/new',
    name: 'new-cases-section',
    meta: {
      pageTitle: 'Section'
    }
  },
  section: {
    path: '/chests-sections/:id',
    name: 'cases-section',
    meta: {
      pageTitle: 'Section',
      resources: [sections.getOne]
    }
  }
}

export const sectionsRoutes = [
  {
    ...path.sections,
    component: () => import('@/views/sections/Sections.vue')
  },
  {
    ...path.sectionNew,
    component: () => import('@/views/sections/Section.vue')
  },
  {
    ...path.section,
    component: () => import('@/views/sections/Section.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      return routerDataResolver(
        to,
        next,
        passDataToResource(sections.getOne, {
          requestParams: {
            urlParams: { id: to.params.id }
          }
        })
      )
    }
  }
]
