import api from '@/services/api'
import { permissions } from '@/plugins/acl'

const moneyBonusUrl = '/bonuses/money'
const refillBonusUrl = '/bonuses/refill'
const bonusUsages = '/bonus-usages'

export default {
  money: {
    permission: permissions.bonusView,
    request: ({ params }) => api.get(moneyBonusUrl, params)
  },
  moneyCreate: {
    permission: permissions.bonusCreate,
    entity: 'Money bonus',
    request: ({ data }) => api.post(moneyBonusUrl, data)
  },
  moneyUpdate: {
    permission: permissions.bonusUpdate,
    entity: 'Money bonus',
    request: ({ urlParams: { id }, data }) => {
      return api.put(`${moneyBonusUrl}/${id}`, data)
    }
  },

  refill: {
    permission: permissions.bonusView,
    request: ({ params }) => api.get(refillBonusUrl, params)
  },
  refillCreate: {
    permission: permissions.bonusCreate,
    entity: 'Refill bonus',
    request: ({ data }) => api.post(refillBonusUrl, data)
  },
  refillUpdate: {
    permission: permissions.bonusUpdate,
    entity: 'Refill bonus',
    request: ({ urlParams: { id }, data }) => {
      return api.put(`${refillBonusUrl}/${id}`, data)
    }
  },

  bonusByIdWithType: {
    permission: permissions.bonusView,
    request: ({ urlParams: { type, id } }) => api.get(`/bonuses/${type}/${id}`)
  },

  usages: {
    permission: permissions.bonusUsageView,
    request: ({ params }) => api.get(bonusUsages, params)
  }
}
