import { permissions } from '@/plugins/acl'
import api from '@/services/api'

const welcomeOfferUrl = '/welcome-offers'
const welcomeOfferEntity = 'Welcome Offer'

export default {
  getAll: {
    permission: permissions.welcomeOfferView,
    request: ({ params }) => api.get(welcomeOfferUrl, params)
  },
  create: {
    permission: permissions.welcomeOfferCreate,
    entity: welcomeOfferEntity,
    request: ({ data }) => api.post(welcomeOfferUrl, data)
  },
  update: {
    permission: permissions.welcomeOfferUpdate,
    entity: welcomeOfferEntity,
    request: ({ urlParams: { id }, data }) => {
      return api.put(`${welcomeOfferUrl}/${id}`, data)
    }
  },
  toggleStatus: {
    permission: permissions.welcomeOfferUpdate,
    entity: welcomeOfferEntity,
    request: ({ urlParams: { id }, data }) => {
      return api.put(`${welcomeOfferUrl}/${id}/toggle-status`, data)
    }
  },
  getAllUsed: {
    permission: permissions.welcomeOfferPlayerView,
    request: ({ params }) => api.get('/welcome-offer-players', params)
  }
}
