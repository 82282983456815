import { passDataToResource, players } from '@/services/resources'
import { routerDataResolver } from '@/router'
import { playerGetOneIncludes } from '@/services/resources/domains/players'

export const path = {
  players: {
    path: '/players',
    name: 'players',
    meta: {
      pageTitle: 'Players',
      resources: [players.getAll]
    }
  },
  player: {
    path: '/players/:id',
    name: 'player',
    meta: {
      pageTitle: 'Player',
      resources: [players.getOne]
    }
  }
}

export const playerRoutes = [
  {
    ...path.players,
    component: () => import('@/views/players/Players.vue')
  },
  {
    ...path.player,
    component: () => import('@//views/players/Player.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      return routerDataResolver(
        to,
        next,
        passDataToResource(players.getOne, {
          requestParams: {
            urlParams: { id: to.params.id },
            params: { include: playerGetOneIncludes }
          }
        })
      )
    }
  }
]
