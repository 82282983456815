import { ref } from '@vue/composition-api'
import { cloneDeep, map } from 'lodash'
import { boolStatuses } from '@/services/resources'

export const toArray = (value) => {
  return Array.isArray(value) ? value : [value]
}

export const boolStatusFormat = (status = false) => {
  return status ? boolStatuses['1'] : boolStatuses['0']
}

export const boolStatusesOptions = map(boolStatuses, (label, value) => ({ label, value }))

export const nullFormatter = (value) => {
  return value === null ? 0 : value
}

export const useCache = (initialValue) => {
  const cache = ref(initialValue)
  const updateCache = (value) => {
    cache.value = cloneDeep(value)
  }

  return {
    cache,
    updateCache
  }
}
