<template>
  <validation-provider
    v-slot="{ errors, valid }"
    :rules="rules"
    :name="name"
    :vid="name"
    slim
  >
    <b-form-group class="checkbox-form-group">
      <b-input-group class="shadow-none">
        <b-form-checkbox
          :id="name"
          v-model="innerValue"
          :indeterminate="indeterminate"
          v-bind="$attrs"
          @change="change"
        />

        <label v-if="label" :for="name" class="ml-1 mb-0 font-small-3">
          {{ label }}
        </label>
      </b-input-group>
      <small v-if="!valid" class="text-danger">
        {{ errors && errors[0] }}
      </small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { computed } from '@vue/composition-api'
import { BFormCheckbox, BInputGroup, BFormGroup } from 'bootstrap-vue'

export default {
  name: 'VxCheckbox',
  components: {
    ValidationProvider,
    BFormCheckbox,
    BInputGroup,
    BFormGroup
  },
  props: {
    value: Boolean,
    rules: [Object, String],
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    // For enable indeterminate view value should be true
    indeterminate: Boolean
  },
  setup (props, { emit }) {
    // Ugly hack to avoid unnecessary input events
    const innerValue = computed({
      get: () => props.value,
      set: () => {}
    })

    const change = (value) => {
      emit('input', value)
    }

    return {
      innerValue,
      change
    }
  }
}
</script>
