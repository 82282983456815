import { ability } from '@/plugins/acl/index'

/**
 * @param permission
 * @returns boolean
 */
const useCan = (permission) => {
  return ability.can(permission)
}

export {
  useCan
}
