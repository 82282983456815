import api from '@/services/api'
import { permissions } from '@/plugins/acl'

export default {
  getAll: {
    permission: permissions.permissionView,
    request: () => api.get('/permissions')
  },
  getOne: {
    permission: permissions.permissionView,
    request: ({ id }) => api.get(`/permissions/${id}`)
  }
}
