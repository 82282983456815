import router, { getRedirectQuery, path } from '@/router'
import { tokenService } from '@/services/auth'
import { useResource, auth } from '@/services/resources'

export default {
  namespaced: true,

  state: {
    data: null,
    loginModal: false
  },

  getters: {
    isLoggedIn: state => Boolean(state.data) && tokenService.hasTokens(),
    id: state => state.data?.id
  },

  mutations: {
    setUserData (state, data) {
      state.data = data
    },

    loginModalToggle (state, val) {
      state.loginModal = !val
    }
  },

  actions: {
    async login ({ state, dispatch }, data) {
      tokenService.setTokens(data)
      await dispatch('app/init', state, { root: true })

      const path = router.currentRoute.query.to || '/'
      await router.push({ path })
    },

    async fetchUser ({ commit }) {
      const { callRequest } = useResource(auth.getUserData)
      const [error, res] = await callRequest()
      if (error) return [error, res]

      commit('setUserData', res.data)
      return [error, res]
    },

    async logout ({ commit, rootState }) {
      const { callRequest } = useResource(auth.logout)
      await callRequest()
      tokenService.resetTokens()

      if (rootState.app.loaded) {
        const query = getRedirectQuery(router.currentRoute)
        router.push({ ...path.login, query }).then()
      }

      commit('setUserData', null)
    },

    async loginModal ({ state, commit }, data) {
      if (data) {
        tokenService.setTokens(data)
      }

      if (router.currentRoute.path !== '/') {
        commit('loginModalToggle', state.loginModal)
      }
    }
  }
}
