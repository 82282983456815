<template>
  <validation-observer
    v-bind="$attrs"
    :ref="name"
    tag="div"
    @submit.prevent="submit"
  >
    <h3 v-if="title" class="mb-1 pl-1 pr-1 pb-1 border-title">
      {{ title }}
    </h3>

    <b-form>
      <slot v-bind="{ loading, can }" />
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { BForm } from 'bootstrap-vue'
import useVxForm from '@/components/form/vx-form/useVxForm'
import { defaultParserRules } from '@/services/form'

export default {
  components: {
    ValidationObserver,
    BForm
  },

  props: {
    name: {
      type: String,
      default: 'observer'
    },
    title: {
      type: String,
      default: null
    },
    resource: {
      type: Object,
      required: true
    },
    parserRules: {
      type: Object,
      default: () => defaultParserRules
    }
  },

  setup (props, ctx) {
    return useVxForm(props, ctx)
  }
}
</script>
<style lang="scss" scoped>
.border-title {
  border-bottom: 1px solid var(--light);
}

.dark-layout {
  .border-title {
    border-bottom: 1px solid var(--dark);
  }
}
</style>
