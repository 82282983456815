import path from '@/router/path'
import { homeRoutes } from '@/views/home/homeRouter'
import { loginRoutes } from '@/views/login/loginRouter'
import { errorsRoutes } from '@/views/error/errorsRouter'
import { playerRoutes } from '@/views/players/playerRouter'
import { tagsRoutes } from '@/views/tags/tagsRouter'
import { dropsRoutes } from '@/views/drops/dropsRouter'
import { withdrawalsRoutes } from '@/views/withdrawals/withdrawalsRouter'
import { provablyFairRoutes } from '@/views/provably-fair/provablyFairRouter'
import { casesRoutes } from '@/views/cases/casesRouter'
import { upgradesRoutes } from '@/views/upgrades/upgradesRouter'
import { exchangesRoutes } from '@/views/exchanges/exchangesRouter'
import { luckyRollsRoutes } from '@/views/lucky-rolls/luckyRollsRouter'
import { welcomeOffersRoutes } from '@/views/welcome-offers/welcomeOffersRouter'
import { paymentsRoutes } from '@/views/payments/paymentsRouter'
import { balanceChangesRoutes } from '@/views/balance-changes/balanceChangesRouter'
import { antifraudRoutes } from '@/views/antifraud/antifraudRouter'
import { giftCardsRoutes } from '@/views/gift-cards/giftCardsRouter'
import { promocodesRoutes } from '@/views/promocodes/promocodesRouter'
import { bonusesRoutes } from '@/views/bonuses/bonusesRouter'
import { skinsRoutes } from '@/views/skins/skinsRouter'
import { adminsRoutes } from '@/views/admins/adminsRouter'
import { textsRoutes } from '@/views/texts/textsRouter'
import { monitoringRoutes } from '@/views/monitoring/monitoringRouter'
import { currenciesRoutes } from '@/views/currencies/currenciesRouter'
import { marketsRoutes } from '@/views/markets/marketsRouter'
import { settingsRoutes } from '@/views/settings/settingsRouter'
import { missionsRoutes } from '@/views/missions/missionsRouter'
import { sectionsRoutes } from '@/views/sections/sectionsRouter'

export default [
  {
    path: '*',
    redirect: path.notFound.name
  },
  ...errorsRoutes,
  ...homeRoutes,
  ...loginRoutes,
  ...playerRoutes,
  ...tagsRoutes,
  ...dropsRoutes,
  ...withdrawalsRoutes,
  ...provablyFairRoutes,
  ...casesRoutes,
  ...upgradesRoutes,
  ...exchangesRoutes,
  ...luckyRollsRoutes,
  ...welcomeOffersRoutes,
  ...paymentsRoutes,
  ...balanceChangesRoutes,
  ...antifraudRoutes,
  ...giftCardsRoutes,
  ...promocodesRoutes,
  ...bonusesRoutes,
  ...skinsRoutes,
  ...textsRoutes,
  ...adminsRoutes,
  ...monitoringRoutes,
  ...currenciesRoutes,
  ...marketsRoutes,
  ...settingsRoutes,
  ...missionsRoutes,
  ...sectionsRoutes
]
