import { permissions } from '@/plugins/acl'
import api from '@/services/api'

export default {
  getAll: {
    permission: permissions.dropView,
    request: ({ params }) => api.get('/drops', params)
  },
  updateStatus: {
    permission: permissions.dropStatusUpdate,
    entity: 'Drop status',
    request: ({ urlParams: { id }, data }) => api.put(`/drops/${id}/status`, data)
  }
}
