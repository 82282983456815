import { Ability, AbilityBuilder } from '@casl/ability'

const ability = new Ability()

const setPermissions = (permissions = []) => {
  const { can, rules } = new AbilityBuilder()

  permissions.forEach(({ name }) => {
    can(name)
  })

  ability.update(rules)
}

export {
  ability,
  setPermissions
}
