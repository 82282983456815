import { missions } from '@/services/resources'

export const path = {
  missions: {
    path: '/missions',
    name: 'missions',
    meta: {
      resources: [missions.getAll],
      pageTitle: 'Missions'
    }
  },
  createMission: {
    path: '/missions/new',
    name: 'create-mission',
    meta: {
      resources: [missions.create],
      pageTitle: 'New mission'
    }
  },
  updateMission: {
    path: '/missions/:id',
    name: 'update-mission',
    meta: {
      resources: [
        missions.update,
        missions.getOne,
        missions.createTask,
        missions.updateTask
      ],
      pageTitle: 'Update mission'
    }
  }
}

export const missionsRoutes = [
  {
    ...path.missions,
    component: () => import('@/views/missions/Missions.vue')
  },
  {
    ...path.createMission,
    component: () => import('@/views/missions/Mission.vue')
  },
  {
    ...path.updateMission,
    component: () => import('@/views/missions/Mission.vue')
  }
]
