import { isNil } from 'lodash'

const addPercentSign = value => `${value || 0}%`

const integerToPercentage = (value, fractionDigits = 2) => {
  return !isNil(value) ? (value / 1000).toFixed(fractionDigits) : 0
}

const percentageToInteger = value => {
  return !isNil(value) ? value * 1000 : 0
}

const percentageFormat = (value, fractionDigits = 2) => addPercentSign(integerToPercentage(value, fractionDigits))

const chanceFormat = value => addPercentSign(integerToChance(value))

const chanceToInteger = value => {
  return !isNil(value) ? value * 1000 : 0
}

const integerToChance = value => {
  return !isNil(value) && value !== 0 ? (value / 1000).toFixed(3) : 0
}

export {
  addPercentSign,
  percentageFormat,
  percentageToInteger,
  integerToPercentage,
  chanceFormat,
  chanceToInteger,
  integerToChance
}
