import { lowerCase, upperFirst } from 'lodash'
import { rxRegexpReplace } from '@/services/utils/regex'

const labelFormat = (str) => {
  return upperFirst(lowerCase(str))
}

const splitText = value => value.join('\n')

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

const getRandomString = (length = 6) => {
  let result = ' '

  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

// Escape characters to be used in building a regular expression
const escapeRegExp = (str) => str.replace(rxRegexpReplace, '\\$&')

export {
  splitText,
  labelFormat,
  getRandomString,
  escapeRegExp
}
