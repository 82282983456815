<template>
  <component
    :is="inputComponent"
    :value="$attrs.value"
    :type="inputType"
    v-bind="$attrs"
    @input="emitValue"
  />
</template>

<script>
import VxCheckbox from '@/components/form/VxCheckbox'
import VxInput from '@/components/form/VxInput'
import VxSelect from '@/components/form/VxSelect'
import { inputsByType } from '@/services/form/formService'
import { computed } from '@vue/composition-api'

export default {
  name: 'VxInputFabric',
  components: {
    VxInput,
    VxSelect,
    VxCheckbox
  },
  props: {
    inputType: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const inputComponent = computed(() => inputsByType[props.inputType])

    const emitValue = (value) => emit('input', value)

    return {
      inputsByType,
      inputComponent,
      emitValue
    }
  }
}
</script>
