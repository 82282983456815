import { permissions } from '@/plugins/acl'
import api from '@/services/api'

export default {
  serverSeeds: {
    permission: permissions.playerServerSeedView,
    request: ({ params }) => api.get('/server-seeds', params)
  },
  clientSeeds: {
    permission: permissions.playerClientSeedView,
    request: ({ params }) => api.get(`/client-seeds`, params)
  },
  rolls: {
    permission: permissions.playerRollView,
    request: ({ params }) => api.get(`/player-rolls`, params)
  }
}
