import JwtService from '@/services/auth/jwtService'
import tokenService from '@/services/auth/tokenService'

const jwtService = new JwtService({
  ignoredUrls: [
    '/auth/login',
    '/auth/refresh-token',
    '/auth/logout'
  ]
})

export {
  jwtService,
  tokenService
}
