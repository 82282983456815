<template>
  <vx-select
    v-bind="$attrs"
    :value="value"
    :name="name"
    :options="options"
    :infinite-scroll="hasPagination"
    :loading="loading"
    @input="emitValue"
    @search="search"
    @nextPage="getOptions"
  />
</template>

<script>
import VxSelect from '@/components/form/VxSelect'
import { ref, watch } from '@vue/composition-api'
import { useResource } from '@/services/resources'

export default {
  name: 'VxSelectResource',
  components: { VxSelect },
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: [Number, String, Boolean, Array],
      default: ''
    },
    resource: {
      type: [Object, Function],
      required: true
    },
    resourceMapper: {
      type: Function,
      default: null
    },
    suppressOptionsPushing: {
      type: Boolean,
      default: false
    },
    initialOptions: {
      type: Array,
      default: () => ([])
    }
  },
  emits: ['input', 'search'],
  setup (props, { emit }) {
    if (!props.resource) {
      console.warn('Select should have api resource')
    }

    const hasPagination = ref(false)
    const options = ref([])
    const currentPage = ref(1)

    let useResourceInstance = useResource(props.resource)

    watch(() => props.resource, async () => {
      if (props.suppressOptionsPushing) {
        options.value.splice(0)
      }

      emitValue(null)
      useResourceInstance = useResource(props.resource)
      await getOptions(true)
    })

    const search = (value) => {
      emit('search', value)
    }

    const emitValue = (value) => {
      emit('input', value)
    }

    const getOptions = async (firstRequest = false, successCallback) => {
      if (!useResourceInstance.can) return

      const params = { per_page: 25 }

      if (firstRequest) {
        currentPage.value = 1
        options.value = props.initialOptions
      } else if (hasPagination.value) {
        currentPage.value++
        params.page = currentPage.value
      }

      const [error, res] = await useResourceInstance.callRequest({ params })
      if (error) return

      const { data, current_page, last_page } = res

      // eslint-disable-next-line camelcase
      hasPagination.value = current_page && last_page && current_page < last_page
      options.value.push(...(props.resourceMapper ? props.resourceMapper(data) : data))

      if (successCallback) {
        successCallback()
      }
    }

    getOptions(true)

    return {
      hasPagination,
      can: useResourceInstance.can,
      loading: useResourceInstance.loading,
      options,

      getOptions,
      search,
      emitValue
    }
  }
}
</script>
