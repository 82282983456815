import store from '@/store'
import { useSkin } from '@/services/app'
import { useVuex } from '@vueblocks/vue-use-vuex'
import { useWindowSize } from '@vueuse/core'
import { watch } from '@vue/composition-api'

const watchLoaded = () => {
  return new Promise((resolve) => {
    const loaded = store.state.app.loaded
    if (loaded) resolve(loaded)

    const watcher = store.watch(
      (state) => state.app.loaded,
      (value) => {
        if (value) {
          resolve(value)
          watcher()
        }
      }
    )
  })
}

const localStorageWatcher = () => {
  const { skin, storageKey: skinStorageKey } = useSkin()

  window.addEventListener('storage', (event) => {
    if (event.storageArea !== localStorage) return

    switch (event.key) {
      case skinStorageKey:
        skin.value = event.newValue
        break
    }
  })
}

const watchAndStoreWindowWidth = () => {
  const { useMutations } = useVuex('app')
  const { updateWindowWidth } = useMutations(['updateWindowWidth'])

  updateWindowWidth(window.innerWidth)
  const { width: windowWidth } = useWindowSize()
  watch(windowWidth, val => {
    updateWindowWidth(val)
  })
}

export {
  watchLoaded,
  useSkin,
  localStorageWatcher,
  watchAndStoreWindowWidth
}
