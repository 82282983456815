import { permissions } from '@/plugins/acl'
import api from '@/services/api'

const systemSettingsUrl = '/settings'

export default {
  getAll: {
    permission: permissions.settingView,
    request: () => api.get(`${systemSettingsUrl}`)
  },
  update: {
    permission: permissions.settingUpdate,
    request: ({ urlParams: { entity }, data }) => {
      return api.patch(`${systemSettingsUrl}/${entity}`, data)
    }
  }
}
