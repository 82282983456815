import api from '@/services/api'
import { permissions } from '@/plugins/acl'

const luckyRollsUrl = '/lucky-rolls'
const entityName = 'Lucky Roll'

export default {
  getAll: {
    permission: permissions.luckyRollView,
    request: ({ params }) => api.get(`${luckyRollsUrl}`, params)
  },
  getOne: {
    permission: permissions.luckyRollView,
    request: ({ urlParams: { id }, params }) => {
      return api.get(`${luckyRollsUrl}/${id}`, params)
    }
  },
  create: {
    permission: permissions.luckyRollCreate,
    request: ({ data }) => api.post(`${luckyRollsUrl}`, data)
  },
  update: {
    permission: permissions.luckyRollUpdate,
    entity: entityName,
    request: ({ urlParams: { id }, data }) => {
      return api.put(`${luckyRollsUrl}/${id}`, data)
    }
  },
  delete: {
    permission: permissions.luckyRollDelete,
    entity: entityName,
    confirm: true,
    request: ({ urlParams: { id } }) => {
      return api.delete(`${luckyRollsUrl}/${id}`)
    }
  },
  prize: {
    permission: permissions.luckyRollPrizeView,
    request: ({ urlParams: { id }, params }) => api.get(`${luckyRollsUrl}/prizes/${id}`, params)
  },
  prizeCreate: {
    permission: permissions.luckyRollPrizeCreate,
    entity: 'Prize',
    request: ({ urlParams: { id }, data }) => api.post(`${luckyRollsUrl}/${id}/prizes`, data)
  },
  prizesUpdate: {
    permission: permissions.luckyRollPrizeUpdate,
    entity: 'Prize',
    request: ({ urlParams: { id }, data }) => {
      return api.put(`${luckyRollsUrl}/prizes/${id}`, data)
    }
  },
  prizeToggle: {
    permission: permissions.luckyRollPrizeUpdate,
    entity: entityName,
    request: ({ urlParams: { id }, data }) => {
      return api.patch(`${luckyRollsUrl}/prizes/${id}/enable`, data)
    }
  },
  winners: {
    permission: permissions.luckyRollResultView,
    request: ({ params }) => api.get(`${luckyRollsUrl}/results`, params)
  }
}
