<template>
  <validation-provider
    v-slot="{ errors, valid }"
    :rules="rules"
    :name="name"
    :vid="name"
    :immediate="immediate"
    slim
  >
    <b-form-group>
      <label v-if="label" :for="name">
        {{ label }}
      </label>

      <b-input-group>
        <b-form-textarea
          :id="name"
          v-model="innerValue"
          v-bind="$attrs"
        />
      </b-input-group>

      <small v-if="$slots.message">
        <slot name="message" />
      </small>

      <small v-if="!valid" class="text-danger">
        {{ errors && errors[0] }}
      </small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { computed } from '@vue/composition-api'
import { BFormGroup, BInputGroup, BFormTextarea } from 'bootstrap-vue'

export default {
  name: 'VxTextarea',
  components: {
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    ValidationProvider
  },
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    name: {
      type: [String],
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    rules: {
      type: [Object, String],
      default: () => ({})
    },
    immediate: Boolean,
    label: {
      type: String,
      default: ''
    }
  },
  emits: ['input'],
  setup (props, { emit }) {
    const innerValue = computed({
      get: () => props.value,
      set: (value) => emit('input', value)
    })

    return { innerValue }
  }
}
</script>
