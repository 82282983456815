import { ref } from '@vue/composition-api'
import { useCan } from '@/plugins/acl'
import { showConfirmation, formatResponse, showNotification } from '@/services/resources/processResource'
import { isFunction, merge } from 'lodash'

const formatResource = (resource) => {
  const {
    permission = null,
    confirm = false,
    suppressNotification = false,
    entity = null,
    request = resource,
    frontToBackMapper = null,
    requestParams = {}
  } = resource
  const can = permission ? useCan(permission) : true

  return {
    can,
    confirm,
    suppressNotification,
    entity,
    request,
    frontToBackMapper,
    requestParams
  }
}

const useResource = (resource) => {
  const loading = ref(false)
  const {
    can,
    confirm,
    suppressNotification,
    entity,
    request,
    requestParams,
    ...restResource
  } = formatResource(resource)

  const callRequest = async (payload) => {
    if (confirm) {
      const confirmResult = showConfirmation(entity)
      if (!confirmResult) {
        // TODO: make a response where the consumer understands that the confirmation failed
        return [null, null]
      }
    }

    loading.value = true
    const requestPayload = merge(payload, requestParams)
    const response = await request(requestPayload)

    if (!suppressNotification) {
      showNotification(response, entity)
    }

    loading.value = false

    return formatResponse(response)
  }

  return {
    loading,
    can,
    request,
    entity,
    requestParams,
    ...restResource,

    callRequest
  }
}

const passDataToResource = (resource, {
  frontToBackMapper,
  requestParams = {}
} = {}) => {
  return {
    ...(isFunction(resource) ? { request: resource } : resource),
    frontToBackMapper,
    requestParams
  }
}

export {
  useResource,
  passDataToResource
}
