import api from '@/services/api'
import { permissions } from '@/plugins/acl'

export default {
  getAll: {
    permission: permissions.paymentView,
    request: ({ params }) => api.get('/payments', params)
  },
  updateStatus: {
    permission: permissions.paymentStatusUpdate,
    suppressNotification: true,
    entity: 'Payment',
    request: ({ urlParams: { id } }) => api.post(`/payments/${id}/update-status`)
  }
}
