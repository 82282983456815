import { permissions } from '@/plugins/acl'
import api from '@/services/api'

const categoriesUrl = '/categories'

export default {
  getAll: {
    permission: permissions.categoryView,
    request: ({ params }) => api.get(`${categoriesUrl}`, params)
  },
  getBaseInfo: {
    permission: permissions.categoryView,
    request: () => api.get(`${categoriesUrl}/base-info`)
  },
  getVariations: {
    permission: permissions.categoryView,
    request: () => api.get(`${categoriesUrl}/variation-list`)
  },
  getNames: {
    permission: permissions.categoryView,
    request: () => api.get(`${categoriesUrl}/name-list`)
  }
}
