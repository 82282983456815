import api from '@/services/api'
import { permissions } from '@/plugins/acl'

const notificationsUrl = '/notifications'
const entityName = 'Notification'

export default {
  getAll: {
    permission: permissions.notificationView,
    request: ({ params }) => api.get(`${notificationsUrl}`, params)
  },
  toggleStatus: {
    permission: permissions.notificationToggleStatus,
    entity: entityName,
    request: ({ urlParams: { id } }) => {
      return api.put(`${notificationsUrl}/${id}/toggle`)
    }
  },
  delete: {
    permission: permissions.notificationDelete,
    entity: entityName,
    confirm: true,
    request: ({ urlParams: { id } }) => {
      return api.delete(`${notificationsUrl}/${id}`)
    }
  },
  create: {
    permission: permissions.notificationCreate,
    request: ({ data }) => api.post(`${notificationsUrl}`, data)
  },
  update: {
    permission: permissions.notificationUpdate,
    request: ({ urlParams: { id }, data }) => {
      return api.put(`${notificationsUrl}/${id}`, data)
    }
  }
}
