<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    ref="providerRef"
    :rules="rules"
    :name="name"
    :vid="name"
    slim
  >
    <b-form-group>
      <label v-if="label">
        {{ label }}
      </label>

      <json-editor
        v-model="innerValue"
        v-bind="$attrs"
        :options="options"
        :plus="false"
        class="json-editor-wrapper"
        @error="setError"
      />

      <small v-if="!valid" class="text-danger">
        {{ errors && errors[0] }}
      </small>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
import JsonEditor from 'v-jsoneditor'
import { ValidationProvider } from 'vee-validate'
import { BFormGroup } from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
import { useToast } from '@/plugins/toastification'

export default {
  name: 'VxJson',
  components: {
    BFormGroup,
    JsonEditor,
    ValidationProvider
  },
  props: {
    value: {
      required: true,
      type: [String, Number, Object, Array]
    },
    rules: [Object, String],
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    showCopy: Boolean,
    mode: {
      type: String,
      default: 'text'
    },
    modes: {
      type: Array,
      default: () => (['tree', 'code', 'form', 'text', 'view'])
    }
  },
  setup (props, { emit }) {
    const toast = useToast()
    const providerRef = ref(null)

    const innerValue = computed({
      get: () => props.value,
      set: (newValue) => emit('input', newValue)
    })

    const change = (value) => {
      innerValue.value = value
    }

    const options = {
      mode: props.mode,
      modes: props.modes,
      language: 'en',
      enableTransform: false,
      enableSort: false
    }

    const setError = () => {
      providerRef.value.applyResult({
        errors: ['Invalid json'],
        valid: false,
        failedRules: {}
      })
    }

    const copy = (space = 0) => {
      navigator.clipboard.writeText(JSON.stringify(innerValue.value, null, space))
      toast.success('Json has been copied to clipboard')
    }

    return {
      providerRef,
      setError,

      innerValue,
      change,

      options,
      copy
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/base/bootstrap-extended.scss';
@import '~@core/scss/base/core/colors/palette-variables.scss';

.dark-layout {
  .json-editor-wrapper ::v-deep .jsoneditor {
    &-outer {
      .ace-jsoneditor.ace_editor {
        .ace_gutter {
          background-color: $theme-dark-border-color;
          .ace_layer {
            .ace_gutter-cell {
              background-color: $theme-dark-border-color;
              color: $white;
            }
          }
        }
        .ace_scroller {
          background-color: $theme-dark-border-color;
          .ace-layer {
            .ace_active-line {
              background-color: $theme-dark-border-color;
            }
          }
          .ace_content {
            background-color: $theme-dark-border-color;

            .ace_text-layer {
              background-color: $theme-dark-border-color;
              color: $white;
            }
          }
        }
      }
    }
    &-statusbar {
      background-color: $theme-dark-border-color;
      border-color: $theme-dark-border-color;
    }
  }
}

.json-editor-wrapper ::v-deep .jsoneditor {
  overflow: unset !important;
  border-color: $primary;
  border-radius: $border-radius;

  &-menu {
    background-color: rgba($primary, 0.8);
    border-bottom-color: $primary;
  }
  &-statusbar {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
.message {
  padding-left: 5px;
}
</style>
