import { components, systemSettings, useResource } from '@/services/resources'
import { routerDataResolver } from '@/router'

export const path = {
  components: {
    path: '/components',
    name: 'components-list',
    meta: {
      pageTitle: 'Components',
      resources: [components.getAll]
    }
  },
  systemSettings: {
    path: '/system-settings',
    name: 'system-settings',
    meta: {
      pageTitle: 'Global settings',
      resources: [systemSettings.getAll]
    },
    props: true,
    beforeEnter: (to, from, next) => {
      return routerDataResolver(
        to,
        next,
        useResource(systemSettings.getAll)
      )
    }
  }
}

export const settingsRoutes = [
  {
    ...path.components,
    component: () => import('@/views/settings/Components')
  },
  {
    ...path.systemSettings,
    component: () => import('@/views/settings/SystemSettings')
  }
]
