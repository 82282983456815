import { currencies, passDataToResource } from '@/services/resources'
import { routerDataResolver } from '@/router'

export const path = {
  currencies: {
    path: '/currencies',
    name: 'currencies-list',
    meta: {
      pageTitle: 'Currencies',
      resources: [currencies.getAll]
    }
  },
  createCurrency: {
    path: '/currencies/new',
    name: 'create currency',
    meta: {
      pageTitle: 'Create Currency',
      resources: [currencies.create]
    }
  },
  updateCurrency: {
    path: '/currencies/:id',
    name: 'currency',
    meta: {
      pageTitle: 'Update Currency',
      resources: [currencies.getOne]
    }
  }
}

export const currenciesRoutes = [
  {
    ...path.currencies,
    component: () => import('@/views/currencies/Currencies.vue')
  },
  {
    ...path.createCurrency,
    component: () => import('@/views/currencies/Currency.vue')
  },
  {
    ...path.updateCurrency,
    component: () => import('@/views/currencies/Currency.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      return routerDataResolver(
        to,
        next,
        passDataToResource(currencies.getOne, {
          requestParams: {
            urlParams: { id: to.params.id },
            params: { include: 'icon' }
          }
        })
      )
    }
  }
]
