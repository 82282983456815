import store from '@/store'
import { watchLoaded } from '@/services/app'
import { buildRoute, getRedirectQuery, path } from '@/router'
import { isEmpty } from 'lodash'
import { useResource } from '@/services/resources'
import { useToast } from '@/plugins/toastification'

export const checkAuth = async (to, from, next) => {
  await watchLoaded()
  const isLoggedIn = store.getters['user/isLoggedIn']

  if (to.name !== 'login' && !isLoggedIn) {
    console.warn('Auth required: forbidden route.')

    const query = getRedirectQuery(to)

    if (query.to === path.forbidden.path) {
      return next(path.login)
    }

    return next({ ...path.login, query })
  }

  if (to.name === 'login' && isLoggedIn) {
    console.warn('You are already logged in!')

    const redirectPath = to.query.to
      ? { path: to.query.to }
      : path.home
    return next(redirectPath)
  }

  return next()
}

export const checkPermission = async (to, from, next) => {
  await watchLoaded()

  const toast = useToast()

  const { meta: { resources = [] } = {} } = to
  if (isEmpty(resources)) return next()

  const failedPermissions = []
  const resolvedPermissions = resources.map(resource => {
    const { can } = useResource(resource)

    if (!can) {
      failedPermissions.push(resource.permission)
    }

    return can
  })

  if (!resolvedPermissions.includes(true)) {
    toast.warning(`You doesn't have needed permission`)

    return next(buildRoute(path.forbidden, { params: { failedPermissions } }))
  }

  return next()
}
