import { balanceChanges } from '@/services/resources'

export const path = {
  balanceChanges: {
    path: '/wallet-changes',
    name: 'wallet-changes-list',
    meta: {
      resources: [balanceChanges.getAll],
      pageTitle: 'Balance Changes'
    }
  },
  balanceChangeRequests: {
    path: '/balance-change-requests',
    name: 'balance-change-requests',
    meta: {
      resources: [
        balanceChanges.getAllRequests,
        balanceChanges.createRequest,
        balanceChanges.updateRequest,
        balanceChanges.rejectRequest,
        balanceChanges.approveRequest
      ],
      pageTitle: 'Balance Change Requests'
    }
  }
}

export const balanceChangesRoutes = [
  {
    ...path.balanceChanges,
    component: () => import('@/views/balance-changes/BalanceChanges.vue')
  },
  {
    ...path.balanceChangeRequests,
    component: () => import('@/views/balance-changes/BalanceChangeRequests.vue')
  }
]
