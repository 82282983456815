import Vue from 'vue'
import { abilitiesPlugin, Can } from '@casl/vue'
import { ability } from '@/plugins/acl/ability'
import { permissions } from '@/plugins/acl/consts'
import { useCan } from '@/plugins/acl/utils'

Vue.use(abilitiesPlugin, ability, {
  useGlobalProperties: true
})
Vue.component('VxCan', Can)

export {
  permissions,
  ability,
  useCan
}
