import { path as errorPath } from '@/views/error/errorsRouter'
import { path as loginPath } from '@/views/login/loginRouter'
import { path as homePath } from '@/views/home/homeRouter'
import { path as playerPath } from '@/views/players/playerRouter'
import { path as tagsPath } from '@/views/tags/tagsRouter'
import { path as dropsPath } from '@/views/drops/dropsRouter'
import { path as withdrawalsPath } from '@/views/withdrawals/withdrawalsRouter'
import { path as provablyFairPath } from '@/views/provably-fair/provablyFairRouter'
import { path as casesPath } from '@/views/cases/casesRouter'
import { path as upgradesPath } from '@/views/upgrades/upgradesRouter'
import { path as exchangesPath } from '@/views/exchanges/exchangesRouter'
import { path as luckyRollsPath } from '@/views/lucky-rolls/luckyRollsRouter'
import { path as welcomeOffersPath } from '@/views/welcome-offers/welcomeOffersRouter'
import { path as paymentsPath } from '@/views/payments/paymentsRouter'
import { path as balanceChangesPath } from '@/views/balance-changes/balanceChangesRouter'
import { path as antifraudPath } from '@/views/antifraud/antifraudRouter'
import { path as giftCardsPath } from '@/views/gift-cards/giftCardsRouter'
import { path as promocodesPath } from '@/views/promocodes/promocodesRouter'
import { path as bonusesPath } from '@/views/bonuses/bonusesRouter'
import { path as skinsPath } from '@/views/skins/skinsRouter'
import { path as textsPath } from '@/views/texts/textsRouter'
import { path as adminsPath } from '@/views/admins/adminsRouter'
import { path as monitoringPath } from '@/views/monitoring/monitoringRouter'
import { path as currenciesPath } from '@/views/currencies/currenciesRouter'
import { path as marketsPath } from '@/views/markets/marketsRouter'
import { path as settingsPath } from '@/views/settings/settingsRouter'
import { path as missionsPath } from '@/views/missions/missionsRouter'
import { path as sectionsPath } from '@/views/sections/sectionsRouter'

const path = {
  ...errorPath,
  ...loginPath,
  ...homePath,
  ...playerPath,
  ...tagsPath,
  ...dropsPath,
  ...withdrawalsPath,
  ...provablyFairPath,
  ...casesPath,
  ...upgradesPath,
  ...exchangesPath,
  ...luckyRollsPath,
  ...welcomeOffersPath,
  ...paymentsPath,
  ...balanceChangesPath,
  ...antifraudPath,
  ...giftCardsPath,
  ...promocodesPath,
  ...bonusesPath,
  ...skinsPath,
  ...textsPath,
  ...adminsPath,
  ...monitoringPath,
  ...currenciesPath,
  ...marketsPath,
  ...settingsPath,
  ...missionsPath,
  ...sectionsPath
}

export default path
