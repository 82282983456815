import { admins, passDataToResource, roles } from '@/services/resources'
import { routerDataResolver } from '@/router'

export const path = {
  admins: {
    path: '/admins',
    name: 'admins-list',
    meta: {
      resources: [
        admins.getAll,
        admins.create
      ],
      pageTitle: 'Admins'
    }
  },
  admin: {
    path: '/admins/:id',
    name: 'admin',
    meta: {
      resources: [admins.getOne],
      pageTitle: 'Admin'
    }
  },
  logs: {
    path: '/logs',
    name: 'logs',
    meta: {
      resources: [admins.logs],
      pageTitle: 'Admin Logs'
    }
  },
  roles: {
    path: '/roles',
    name: 'roles',
    meta: {
      resources: [
        roles.getAll,
        roles.delete
      ],
      pageTitle: 'Roles'
    }
  },
  createRole: {
    path: '/roles/new',
    name: 'role-create',
    meta: {
      resources: [roles.create],
      pageTitle: 'Create Role'
    }
  },
  updateRole: {
    path: '/roles/:id',
    name: 'role-edit',
    meta: {
      resources: [
        roles.getOne,
        roles.update
      ],
      pageTitle: 'Update Role'
    }
  }
}

export const adminsRoutes = [
  {
    ...path.admins,
    component: () => import('@/views/admins/admins/Admins.vue')
  },
  {
    ...path.admin,
    component: () => import('@/views/admins/admin/Admin.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      const resource = passDataToResource(admins.getOne, {
        requestParams: {
          urlParams: { id: to.params.id }
        }
      })
      return routerDataResolver(to, next, resource)
    }
  },
  {
    ...path.logs,
    component: () => import('@/views/admins/logs/AdminLogs.vue')
  },
  {
    ...path.roles,
    component: () => import('@/views/admins/roles/Roles.vue')
  },
  {
    ...path.createRole,
    component: () => import('@/views/admins/role/Role.vue')
  },
  {
    ...path.updateRole,
    component: () => import('@/views/admins/role/Role.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      const resource = passDataToResource(roles.getOne, {
        requestParams: {
          urlParams: { id: to.params.id },
          params: { include: 'permissions' }
        }
      })
      return routerDataResolver(to, next, resource)
    }
  }
]
