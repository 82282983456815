import api from '@/services/api'
import { permissions } from '@/plugins/acl'

export default {
  uploadImage: {
    permission: permissions.faqView,
    entity: 'Image',
    request: ({ data, headers }) => api.post('file/upload-image', data, headers)
  }
}
