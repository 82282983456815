export const path = {
  login: {
    path: '/login',
    name: 'login'
  }
}

export const loginRoutes = [
  {
    ...path.login,
    component: () => import('@/views/login/LoginPage.vue'),
    meta: {
      layout: 'full'
    }
  }
]
