import api, { httpStatuses } from '@/services/api'
import tokenService from '@/services/auth/tokenService'
import { auth } from '@/services/resources'
import store from '@/store'
import { watchEffect } from '@vue/composition-api'

export default class JwtService {
  _ignoredUrls = []
  _refreshing = false
  _refreshingQueue = []

  constructor ({ ignoredUrls }) {
    this._ignoredUrls = ignoredUrls
    this._initInterceptors()
  }

  _initInterceptors () {
    api.axios.interceptors.request.use(this._useBearer.bind(this))

    api.axios.interceptors.response.use(
      (response) => response,
      this._handleUnauthorized.bind(this)
    )
  }

  _useBearer (request) {
    const { token } = tokenService
    if (token) request.headers.Authorization = 'Bearer ' + token
    return request
  }

  _handleUnauthorized (error) {
    const { config, response: { status } = {} } = error
    const originalRequest = config

    const isUnauthorizedStatus = status === httpStatuses.unauthorized
    const urlShouldBeSkipped = this._ignoredUrls.includes(config.url)

    if (!isUnauthorizedStatus || urlShouldBeSkipped) {
      return Promise.reject(error)
    }

    if (!tokenService.refreshToken) {
      this._onFailedRefresh().then()
      return
    }

    // start refreshing
    if (!this._refreshing) {
      this._refreshing = true

      auth.refresh({ data: { refresh_token: tokenService.refreshToken } })
        .then(async ([error, res]) => {
          if (error) {
            this._onFailedRefresh().then()
            return
          }
          const { access_token, refresh_token } = res.data.data
          tokenService.setTokens({ access_token, refresh_token })
          this._processQueue(access_token)
        })
        .finally(() => {
          this._refreshing = false
        })
    }

    // return Promise to request instead error and store original request
    return new Promise((resolve, reject) => {
      this._enqueue((token) => {
        if (!token) return reject(error)

        originalRequest.headers.Authorization = `Bearer ${token}`
        resolve(api.axios(originalRequest))
      })
    })
  }

  _enqueue (callback) {
    this._refreshingQueue.push(callback)
  }

  _processQueue (accessToken) {
    this._refreshingQueue = this._refreshingQueue.filter(callback => callback(accessToken))
  }

  async _onFailedRefresh () {
    await tokenService.resetTokens()
    await store.dispatch('user/loginModal')
    const watcher = watchEffect(() => {
      if (!store.state.user.loginModal) {
        this._processQueue(tokenService.token)

        if (watcher) {
          watcher()
        }
      }
    })
  }
}
