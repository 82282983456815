import { bots, markets, passDataToResource } from '@/services/resources'
import { routerDataResolver } from '@/router'

export const path = {
  markets: {
    path: '/markets',
    name: 'markets-list',
    meta: {
      resources: [markets.getAll],
      pageTitle: 'Markets'
    }
  },
  marketSkins: {
    path: '/markets/:id/skins',
    name: 'market-skin-items-list',
    meta: {
      resources: [
        markets.getOne,
        markets.getAllSkins
      ],
      pageTitle: 'Market Skins'
    }
  },
  bots: {
    path: '/bots',
    name: 'bots',
    meta: {
      resources: [bots.getAll],
      pageTitle: 'Market Bots'
    }
  },
  createBot: {
    path: '/bots/new',
    name: 'create-bot',
    meta: {
      resources: [bots.create],
      pageTitle: 'Create Bot'
    }
  },
  updateBot: {
    path: '/bots/:id',
    name: 'update-bot',
    meta: {
      resources: [bots.getOne],
      pageTitle: 'Update Bot'
    }
  }
}

export const marketsRoutes = [
  {
    ...path.markets,
    component: () => import('@/views/markets/Markets.vue')
  },
  {
    ...path.marketSkins,
    component: () => import('@/views/markets/MarketSkins')
  },
  {
    ...path.bots,
    component: () => import('@/views/markets/Bots')
  },
  {
    ...path.createBot,
    component: () => import('@/views/markets/bot/Bot')
  },
  {
    ...path.updateBot,
    component: () => import('@/views/markets/bot/Bot'),
    props: true,
    beforeEnter: (to, from, next) => {
      return routerDataResolver(
        to,
        next,
        passDataToResource(bots.getOne, {
          requestParams: {
            urlParams: { id: to.params.id }
          }
        })
      )
    }
  }
]
