import Vue from 'vue'
import {
  provideToast as _provideToast,
  useToast as _useToast
} from 'vue-toastification/composition'
import { POSITION } from 'vue-toastification'
import ToastComponent from './Toast'

// Toast Notification Component Styles
import '@core/scss/vue/libs/toastification.scss'
import { appInstance } from '@/services/app'

const settings = {
  hideProgressBar: true,
  closeOnClick: false,
  closeButton: false,
  icon: false,
  timeout: 5000,
  transition: 'Vue-Toastification__fade',
  position: POSITION.BOTTOM_RIGHT
}

// Inject intro Options API components
Vue.use(ToastComponent, settings)

// Provide toast for Composition API usage
// This for those apps/components which uses composition API
export function provideToast () {
  _provideToast(settings)
  Vue.prototype.$toast = _useToast()
}

export function useToast () {
  const _toast = appInstance().$toast
  const showToast = (variant, _title, _text, icon) => {
    const title = _title && typeof _title !== 'string' ? _title.toString() : _title
    let text

    if (_text) {
      if (_text instanceof Error) {
        text = _text.message || text.toString()
      } else if (typeof _text !== 'string') {
        text = _text.toString()
      }
    }

    return _toast({
      component: ToastComponent,
      props: {
        title,
        text,
        variant,
        icon
      }
    })
  }

  const toast = (title, text, icon = 'InfoIcon') => showToast('info', title, text, icon)

  toast.success = (title, text, icon = 'CheckIcon') => showToast('success', title, text, icon)
  toast.warning = (title, text, icon = 'AlertTriangleIcon') => showToast('warning', title, text, icon)
  toast.error = (title, text, icon = 'XIcon') => showToast('danger', title, text, icon)
  toast.info = (title, text, icon = 'InfoIcon') => showToast('info', title, text, icon)

  return toast
}
