import { permissions } from '@/plugins/acl'
import api from '@/services/api'

const entityName = 'Gift Card'
const giftCardKeysUrl = '/gift-card-keys'
const giftCardsUrl = '/gift-cards'

export default {
  getAll: {
    permission: permissions.giftCardView,
    request: ({ params }) => api.get(`${giftCardsUrl}`, params)
  },
  getKeys: {
    permission: permissions.giftCardKeyView,
    request: ({ urlParams: { id }, params }) => api.get(`${giftCardsUrl}/${id}/keys`, params)
  },
  create: {
    permission: permissions.giftCardCreate,
    entity: entityName,
    request: ({ data }) => api.post(`${giftCardsUrl}`, data)
  },
  update: {
    permission: permissions.giftCardUpdate,
    entity: entityName,
    request: ({ urlParams: { id }, data }) => api.put(`${giftCardsUrl}/${id}`, data)
  },
  generate: {
    permission: permissions.giftCardKeyCreate,
    entity: entityName,
    request: ({ urlParams: { id }, data }) => {
      return api.post(`${giftCardKeysUrl}/${id}/generate`, data)
    }
  },
  details: {
    permission: permissions.giftCardKeyView,
    request: ({ params }) => api.get(`${giftCardKeysUrl}`, params)
  },
  updateKeyStatus: {
    permission: permissions.giftCardKeyUpdate,
    entity: entityName,
    request: ({ urlParams: { id }, data }) => api.put(`${giftCardKeysUrl}/${id}`, data)
  },
  statuses: {
    request: () => api.get(`${giftCardKeysUrl}/statuses`)
  }
}
