import { forEach, isArray, isEmpty, isInteger, isNull, isObject } from 'lodash'
import { Validator } from 'vee-validate'
import { differenceInSeconds } from 'date-fns'

/**
 * Convert validation error response from api to object available to use in vee-validate
 * Get messages from errors vocabulary
 * Example:
 *  fields = {name: ["validation.unique", "validation.some_error"]}
 *  return = [
 *    {field: 'name', msg: 'The name field must be unique'},
 *    {field: 'name', msg: 'some_error'}
 *  ]
 *  with prefix 'admin'
 *  return = [
 *    {field: 'admin.name', msg: 'The name field must be unique'},
 *    {field: 'admin.name', msg: 'some_error'}
 *  ]
 * @param {object} fields - API response
 * @param {object=} params - API response
 * @param {object=} params.relatedFields - { "apiFieldName": "clientFormFieldName" }
 * @param {string=} params.prefix - Form scope
 * @returns {array} - FieldError[] vee-validate error interface
 */
const getParsedFieldsWithErrors = (fields, { relatedFields = {}, prefix = '' } = {}) => {
  const fieldsWithErrors = []
  forEach(fields, (errors, apiFieldName) => {
    const field = relatedFields[apiFieldName] || apiFieldName
    errors.forEach((error) => {
      const errorKey = error.replace(/^\w+\./, '')
      const getErrorMessage = errorMessages[errorKey]
      const msg = getErrorMessage ? getErrorMessage(field) : error
      const formFieldName = prefix ? `${prefix}.${field}` : field
      fieldsWithErrors.push({ field: formFieldName, msg })
    })
  })
  return fieldsWithErrors
}

const errorMessages = {
  unique: (fieldName) => `The ${fieldName} field must be unique`
}

const getFormattedValidationErrorsMessage = (fields, params) => {
  return getParsedFieldsWithErrors(fields, params).reduce((res, { msg }) => {
    res += `${msg}\n`
    return res
  }, 'Validation errors:\n')
}

const apiDefaultValidators = {
  maxSmallInt: 65535,
  maxMediumIntUnsignedWithDecimal: 167772.15,
  maxTinyTextLength: 255,
  phonePattern: /^\+\d+$/i
}

const frontDefaultValidators = {
  number: {
    required: true,
    numeric: true,
    min_value: 1
  },
  string: {
    required: true,
    numeric: false
  }
}

const isFilled = (value) => {
  return !(value === null || value === '')
}

const resultForLikeRequiredRules = (valid) => ({
  valid,
  data: {
    required: true
  }
})

Validator.extend(
  'lucky_rolls_roll_values',
  {
    validate: (values) => {
      if (!isArray(values)) return true

      const singleValueRules = {
        integer: (val) => isInteger(Number(val)),
        min: (val) => val >= 1,
        max: (val) => val <= 100000
      }

      const lessThanZero = (a, b) => (a - b) < 0

      const validate = (a, b = null) => {
        let isValid = true

        forEach(singleValueRules, (rule) => {
          if (!b) {
            if (!rule(a)) isValid = false
            return isValid
          }
          if (!rule(a) || !rule(b)) isValid = false
          return isValid
        })

        return isValid
      }

      const handleValues = (a, b = null) => {
        const isValid = validate(a, b)

        return isValid && b ? lessThanZero(a, b) : isValid
      }

      const result = values.map(item => {
        const arr = item.split('-')

        switch (true) {
          case arr.length === 1:
            return handleValues(arr[0])
          case arr.length === 2:
            return handleValues(arr[0], arr[1])
          default:
            return false
        }
      })

      return !result.includes(false)
    },
    getMessage: (field) => `
        The ${field} field must be single integer (from 1 to 100000) or range [smallerInteger - largerInteger]
      `
  }
)

Validator.extend(
  'required_range',
  {
    validate: (range) => {
      if (!Array.isArray(range)) return resultForLikeRequiredRules(false)

      let isValid = Boolean(range.length)
      range.forEach((at) => {
        if (!at) {
          isValid = false
        }
      })
      return resultForLikeRequiredRules(isValid)
    },
    getMessage: field => `The ${field} field is required`
  },
  { computesRequired: true }
)

Validator.extend(
  'min_value_range',
  {
    validate: (range, { value, step }) => {
      if (!Array.isArray(range) || range.length !== 2) return false

      const steps = {
        hours: {
          seconds: 3600
        }
      }

      const difMode = steps[step]

      const difInSeconds = differenceInSeconds(range[1], range[0])
      return (difInSeconds / difMode.seconds) >= value
    },
    getMessage: (field, { value, step }) => {
      return `The ${field} field range must be ${value} ${step} or more`
    }
  }
)

Validator.extend(
  'more_than_today',
  {
    validate: (values) => {
      const today = new Date().getTime()
      const inputValue = new Date(values).getTime()

      return inputValue > today
    }
  }
)

Validator.extend(
  'integer_array',
  {
    validate: (array) => {
      if (!isArray(array)) return true

      const index = array.findIndex((value) => {
        return !Number.isInteger(Number(value))
      })
      return index === -1
    },
    getMessage: (field) => `The ${field} field must have integers`
  }
)

Validator.extend(
  'max_length_array',
  {
    validate: (array, [max]) => {
      if (!isArray(array)) return true

      return array.length <= max
    },
    getMessage: (field, [max]) => `The ${field} field must have ${max} items or less`
  }
)

Validator.extend(
  'min_length_array',
  {
    validate: (array, [min]) => {
      if (!isArray(array)) return true

      return array.length >= min
    },
    getMessage: (field, [min]) => `The ${field} field must have ${min} items or more`
  }
)

Validator.extend(
  'max_item_value',
  {
    validate: (array, [max]) => {
      if (!isArray(array)) return true

      const index = array.findIndex((value) => Number(value) > max)
      return index === -1
    },
    getMessage: (field, [max]) => `The ${field} field items must be ${max} or less`
  }
)

Validator.extend(
  'min_item_value',
  {
    validate: (array, [min]) => {
      if (!isArray(array)) return true

      const index = array.findIndex((value) => Number(value) < min)
      return index === -1
    },
    getMessage: (field, [min]) => `The ${field} field items must be ${min} or more`
  }
)

Validator.extend(
  'nullable_or_min_value',
  {
    validate: (value, [min]) => {
      if (isNull(value)) return true

      return value >= min
    },
    getMessage: (field, [min]) => `The ${field} field must be ${min} or more`
  }
)

const minGenerationPeriod = 1
const maxGenerationPeriod = 18
Validator.extend(
  'generation_period_validator',
  {
    validate: (value) => {
      if (value === null) return true
      const numberValue = Number(value)
      return numberValue >= minGenerationPeriod && numberValue <= maxGenerationPeriod
    },
    getMessage: field => `The ${field} must be between ${minGenerationPeriod} and ${maxGenerationPeriod}`
  }
)

Validator.extend(
  'is_bigger',
  {
    validate: (value, [otherValue]) => {
      return Number(value) >= Number(otherValue)
    },
    getMessage: (field, [otherField]) => {
      return `The ${field} must be greater then ${otherField}`
    }
  },
  { hasTarget: true }
)

Validator.extend(
  'required_json',
  {
    validate: (value) => {
      return isObject(value) && !isEmpty(value)
    },
    getMessage: (field) => `The ${field} field is not JSON`
  }
)

export {
  isFilled,
  apiDefaultValidators,
  frontDefaultValidators,
  getParsedFieldsWithErrors,
  getFormattedValidationErrorsMessage
}
