export const path = {
  queue: {
    path: '/queue-monitoring',
    name: 'queue-monitoring',
    meta: {
      pageTitle: 'Queue Monitoring'
    }
  },
  global: {
    path: '/global-monitoring',
    name: 'global-monitoring',
    meta: {
      pageTitle: 'Global Monitoring'
    }
  }
}

export const monitoringRoutes = [
  {
    ...path.queue,
    component: () => import('@/views/monitoring/QueueMonitoring')
  },
  {
    ...path.global,
    component: () => import('@/views/monitoring/GlobalMonitoring')
  }
]
