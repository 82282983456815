import { provablyFair } from '@/services/resources'

export const path = {
  serverSeeds: {
    path: '/server-seeds',
    name: 'server-seeds',
    meta: {
      pageTitle: 'Server Seeds',
      resources: [provablyFair.serverSeeds]
    }
  },
  clientSeeds: {
    path: '/client-seeds',
    name: 'client-seeds',
    meta: {
      pageTitle: 'Client Seeds',
      resources: [provablyFair.clientSeeds]
    }
  },
  rolls: {
    path: '/rolls',
    name: 'rolls',
    meta: {
      pageTitle: 'Rolls',
      resources: [provablyFair.rolls]
    }
  }
}

export const provablyFairRoutes = [
  {
    ...path.serverSeeds,
    component: () => import('@/views/provably-fair/ServerSeeds')
  },
  {
    ...path.clientSeeds,
    component: () => import('@/views/provably-fair/ClientSeeds')
  },
  {
    ...path.rolls,
    component: () => import('@/views/provably-fair/Rolls')
  }
]
