import { onMounted, computed, watch, ref } from '@vue/composition-api'
import { httpStatuses } from '@/services/api'
import { defaultParserRules, getParsedFieldsWithErrors } from '@/services/form'
import { useToast } from '@/plugins/toastification'
import { useResource } from '@/services/resources'
import { isFunction, mapValues } from 'lodash'

export default function useVxForm (props, { emit, refs }) {
  const { name, parserRules } = props
  const toast = useToast()

  const resource = ref(null)

  // needed for case when resource in props is computed property
  watch(() => props.resource, (value) => {
    resource.value = useResource(value)
  }, { immediate: true })

  let observerRef = null

  // Init observer reference
  // TODO: need to rewrite after update vee-validate to 3v, refs doesn't work in Vue3
  onMounted(() => {
    observerRef = refs[name]
  })

  const submit = async () => {
    if (!resource.value.can) {
      toast.error(`You don't have permissions for this form`)
      return
    }

    const isValid = await observerRef.validate()
    if (!isValid) return

    const response = await makeRequest()
    emit('submit', response)
  }

  const makeRequest = async () => {
    const validationParserRules = {
      ...defaultParserRules,
      ...parserRules
    }

    const values = getValuesFromObserver()

    const { requestParams, frontToBackMapper, callRequest } = resource.value

    const data = isFunction(frontToBackMapper) ? frontToBackMapper(values) : values
    const formattedRequestParams = isFunction(requestParams) ? requestParams(data) : requestParams

    const [err, res] = await callRequest({ ...formattedRequestParams, data })
    if (!err) return [err, res]

    const { data: { message, fields = [] }, status } = err
    const { relatedFields, prefix, messageForStatus } = validationParserRules

    switch (status) {
      case messageForStatus.status:
        setValidationErrors([messageForStatus])
        break
      case httpStatuses.failedValidation:
        setValidationErrors(
          getParsedFieldsWithErrors(
            fields,
            { relatedFields, prefix }
          )
        )
        break
      default:
        toast.error(message)
    }

    return [err, res]
  }

  const getValuesFromObserver = () => {
    return mapValues(observerRef.refs, 'value')
  }

  const setValidationErrors = (errors = []) => {
    errors.forEach((error) => {
      const provider = observerRef.refs[error.field]
      if (!provider) return

      provider.applyResult({
        errors: [error.msg],
        valid: false,
        failedRules: {}
      })
    })
  }

  const resetErrors = () => {
    requestAnimationFrame(() => {
      observerRef.reset()
    })
  }

  return {
    can: computed(() => resource.value.can),
    loading: computed(() => resource.value.loading),
    observerRef,

    submit,
    setValidationErrors,
    resetErrors
  }
}
