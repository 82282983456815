import api from '@/services/api'
import { permissions } from '@/plugins/acl'

const missionsPath = '/missions'
const missionEntity = 'Mission'
const taskEntity = 'Task'

export default {
  getAll: {
    permission: permissions.missionView,
    request: ({ params }) => api.get(missionsPath, params)
  },
  getOne: {
    permission: permissions.missionView,
    request: ({ urlParams: { id } }) => api.get(`${missionsPath}/${id}`)
  },
  create: {
    permission: permissions.missionCreate,
    entity: missionEntity,
    request: ({ data }) => api.post(missionsPath, data)
  },
  update: {
    permission: permissions.missionUpdate,
    entity: missionEntity,
    request: ({ urlParams: { id }, data }) => api.put(`${missionsPath}/${id}`, data)
  },
  updateOrder: {
    permission: permissions.missionUpdate,
    entity: 'Mission order',
    request: ({ data }) => api.put(`${missionsPath}/update-order`, data)
  },
  delete: {
    permission: permissions.missionDelete,
    entity: missionEntity,
    confirm: true,
    request: ({ urlParams: { id } }) => api.delete(`${missionsPath}/${id}`)
  },
  getAllTasks: {
    permission: permissions.missionTaskView,
    request: ({ urlParams: { id }, params }) => api.get(`${missionsPath}/${id}/tasks`, params)
  },
  createTask: {
    permission: permissions.missionTaskCreate,
    entity: taskEntity,
    request: ({ urlParams: { id }, data }) => api.post(`${missionsPath}/${id}/tasks`, data)
  },
  updateTask: {
    permission: permissions.missionTaskUpdate,
    entity: taskEntity,
    request: ({ urlParams: { missionId, taskId }, data }) =>
      api.put(`${missionsPath}/${missionId}/tasks/${taskId}`, data)
  },
  deleteTask: {
    permission: permissions.missionTaskDelete,
    entity: taskEntity,
    confirm: true,
    request: ({ urlParams: { missionId, taskId } }) =>
      api.delete(`${missionsPath}/${missionId}/tasks/${taskId}`)
  }
}
