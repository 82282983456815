<template>
  <validation-provider
    v-slot="{ errors, valid }"
    :rules="rules"
    :name="name"
    :vid="name"
    slim
  >
    <b-form-group>
      <label v-if="label" :for="name">
        {{ label }}
      </label>

      <b-overlay
        :show="loading"
        rounded
      >
        <div
          v-if="!fileSource"
          class="image-picker form-control image-picker-empty"
          @click="triggerFileInput"
        >
          <feather-icon size="24" icon="CameraIcon" />
        </div>

        <div
          v-if="fileSource"
          class="image-picker form-control image-picker-preview"
          @click="triggerFileInput"
        >
          <b-img
            :src="fileSource"
            fluid
          />
        </div>
        <input v-show="false" v-model="innerValue">
        <b-form-file
          v-show="false"
          :id="name"
          ref="refInputEl"
          @change="upload"
        />
      </b-overlay>

      <small v-if="!valid" class="text-danger">
        {{ errors && errors[0] }}
      </small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { BFormFile, BFormGroup, BImg, BOverlay } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { file, useResource } from '@/services/resources'

export default {
  name: 'VxImageUploader',
  components: {
    ValidationProvider,
    BFormGroup,
    BFormFile,
    BImg,
    BOverlay
  },
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    rules: {
      type: [Object, String],
      default: () => ({})
    },
    label: {
      type: String,
      required: true
    },
    initialSrc: {
      type: String,
      default: ''
    },
    name: {
      type: [String],
      required: true
    }
  },
  setup (props, { emit }) {
    const fileSource = ref(props.initialSrc)
    const { loading, callRequest } = useResource(file.uploadImage)

    const innerValue = computed({
      get: () => props.value,
      set: (newValue) => emit('input', newValue)
    })

    // TODO: upload file after click on main submit form, not on choose file like it's happening now
    const upload = async (event) => {
      const files = event.target.files
      const image = files.length ? files[0] : null

      innerValue.value = null

      if (!image) return

      setPreviewImage(image)

      const data = new FormData()
      data.append('image', image)
      data.append('name', image.name)
      data.append('description', image.name)

      const headers = { 'Content-Type': 'multipart/form-data' }

      const [err, res] = await callRequest({ data, headers })
      if (err) return

      innerValue.value = res.data.id
    }

    const setPreviewImage = (file) => {

      const reader = new FileReader()
      reader.onload = () => {
        fileSource.value = reader.result
      }
      reader.readAsDataURL(file)
    }

    const refInputEl = ref(null)

    const triggerFileInput = () => {
      refInputEl.value.$el.childNodes[0].click()
    }

    return {
      innerValue,
      fileSource,

      refInputEl,
      triggerFileInput,

      loading,
      upload
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/base/bootstrap-extended.scss';

.form-group ::v-deep {
  & > div {
    display: flex;
    flex-direction: column;
    width: auto;
  }
}

.image-picker {
  height: 115px;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &-empty {
    width: 100%;
    align-items: center;
    transition: all .3s ease;
    cursor: pointer;
  }

  &-preview {
    img {
      object-fit: contain;
    }
  }
}
</style>
