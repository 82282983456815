import { getCurrentInstance } from '@vue/composition-api'

let instance = null

const setAppInstance = () => {
  instance = getCurrentInstance().proxy
}

const appInstance = () => {
  return instance
}

export { setAppInstance, appInstance }
